import { isEmpty } from 'lodash';
import { getISODate } from './helpers/date';
import { fetcher } from './helpers/fetch';

/**
 *
 * @param {Object} param
 * @param {Date} param.startDate
 * @param {Date} param.endDate
 * @param {?string} param.userId
 * @param {[string]=} param.types
 * @param {?string} param.sort
 * @param {string=} param.bearerToken
 * @return {Promise}
 */
export const getRemoteClocks = ({
  startDate,
  endDate,
  types,
  userId = null,
  sort = null,
  bearerToken,
}) => {
  const startISODate = getISODate(startDate);
  const endISODate = getISODate(endDate);

  let query = `startDate=${startISODate}&endDate=${endISODate}`;

  if (!isEmpty(types)) {
    query += `&type=${types.join('&type=')}`;
  }

  if (userId !== null) {
    query += `&userId=${userId}`;
  }

  if (sort !== null) {
    query += `&sort=${sort}`;
  }

  return fetcher(`api/remote-clocks?${query}`, {
    headers: { Authorization: bearerToken },
  });
};

/**
 * 新增一筆打卡記錄
 * @param {Object} param
 * @param {string} param.type
 * @param {string} param.userId
 * @param {Date | string} [param.date]
 * @param {Boolean} [param.remote]
 * @param {string=} param.bearerToken
 * @return {Promise}
 */
export const postRemoteClocks = ({
  type,
  userId,
  date,
  location,
  photoPath,
  filePath,
  bearerToken,
} = {}) => {
  const ISODate = getISODate(date);

  return fetcher('api/remote-clocks', {
    method: 'POST',
    body: JSON.stringify([
      { date: ISODate, type, userId, location, photoPath, filePath },
    ]),
    headers: {
      'Content-Type': 'application/json',
      Authorization: bearerToken,
    },
  });
};
