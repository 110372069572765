import { compareAsc, differenceInSeconds, set, startOfDay } from 'date-fns';
/**
 * 計算上班小時。取各種狀況
 * x     x     x
 *     |     |
 *   y     y     y
 * @param {Date} oldDate
 * @param {Date} earlyDate
 * @param {{start: {hour: number, minute: number}, end: {hour: number, minute: number}}} lunchBreak 午休時間
 * @return {number}
 */
export const calNormalHour = (oldDate, earlyDate, lunchBreak) => {
  if (earlyDate > oldDate) {
    throw new Error('上下班時間可能放反');
  }

  return (
    Math.round(
      Math.min(
        differenceInSeconds(oldDate, earlyDate) / 3600,
        Math.max(
          differenceInSeconds(
            set(startOfDay(oldDate), {
              hours: lunchBreak.start.hour,
              minutes: lunchBreak.start.minute,
            }),
            earlyDate
          ) / 3600,
          0
        ) +
          Math.max(
            differenceInSeconds(
              oldDate,
              set(startOfDay(oldDate), {
                hours: lunchBreak.end.hour,
                minutes: lunchBreak.end.minute,
              })
            ) / 3600,
            0
          ),
        8
      ) * 100
    ) / 100
  );
};

/**
 * 計算當天所有加班小時
 * 由前往後計算兩兩成對的加班時間
 *
 * @param {[{type: string, date: Date}]} dates 從新到舊 [加班結束, 加班, ...] (可以不符合順序)
 * @return {number}
 */
export const calOverTimeHour = (dates) => {
  let hours = 0;
  let startOverTime = false;
  let endOverTime = false;

  const orderByTimeDates = dates.sort((a, b) =>
    compareAsc(new Date(a.date), new Date(b.date))
  );

  for (let i = 0; i < orderByTimeDates.length; i += 1) {
    if (orderByTimeDates[i].type === '加班') {
      startOverTime = orderByTimeDates[i];
    }
    if (startOverTime && orderByTimeDates[i].type === '加班結束') {
      endOverTime = orderByTimeDates[i];
    }

    if (startOverTime && endOverTime) {
      hours += differenceInSeconds(
        new Date(endOverTime.date),
        new Date(startOverTime.date)
      );
      startOverTime = false;
      endOverTime = false;
    }
  }

  return Math.round((hours / 3600) * 100) / 100;
};

/**
 * 回傳上班時間
 * @param {[{type: string, date: Date}]} clocks
 * @param {{start: {hour: number, minute: number}, end: {hour: number, minute: number}}} lunchBreak 午休時間
 * @return {number}
 */
export const calNormalHourByClocks = (clocks, lunchBreak) => {
  /**
   * 當天第一個上班卡
   * 當天最後一個下班卡
   */
  const normalStart = [...clocks].reverse().find((v) => v.type === '上班');
  const normalEnd = clocks.find((v) => v.type === '下班');

  // 都沒有打卡的話，代表沒上班，所以回傳 0
  if (normalStart === undefined && normalEnd === undefined) return 0;

  // 前面過濾掉兩個都沒打卡的狀況，這裡是只要有一個不存在就代表可能忘記打卡，因此回傳異常
  if (normalStart === undefined || normalEnd === undefined) return -1;

  return calNormalHour(
    new Date(normalEnd.date),
    new Date(normalStart.date),
    lunchBreak
  );
};

/**
 * 回傳加班時間
 * @param {[{type: string, date: Date}]} clocks
 * @return {number}
 */
export const calOverTimeHourByClocks = (clocks) => {
  const overTimeClocks = clocks.filter(
    (v) => v.type === '加班' || v.type === '加班結束'
  );

  return calOverTimeHour(overTimeClocks);
};
