import { fetcher } from './helpers/fetch';

/**
 *
 * @param {{ userName: string, password: string, company: string, bearerToken?: string }}
 * @return {Promise}
 */
export const signIn = ({ userName, password, company, bearerToken }) =>
  fetcher('api/account/signin', {
    method: 'POST',
    body: JSON.stringify({ userName, password, company }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: bearerToken,
    },
  });

/**
 *
 * @param {Object} param
 * @param {string} param.userId
 * @param {string} param.userName
 * @param {string} param.company
 * @param {string} param.password
 * @param {string=} param.bearerToken
 * @return {Promise}
 */
export const signUp = ({ userId, userName, company, password, bearerToken }) =>
  fetcher('api/account/signup', {
    method: 'POST',
    body: JSON.stringify({ userId, userName, company, password }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: bearerToken,
    },
  });
