import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { postClocks } from 'api/clocks';
import { parse } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSetting } from '../../ducks/setting';
import useSnackbar from '../../hooks/useSnackbar';

function StartNormalButton({ userId, clocks, setClocks }) {
  const [open, setOpen] = useState(false);
  const delayPrompt = useSelector(({ setting }) => setting.delayPrompt);
  const { errorSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSetting());
  }, [dispatch]);

  const onClose = () => setOpen(false);

  const onClickStartNormalButton = async () => {
    const payload = await postClocks({
      type: '上班',
      userId,
      date: new Date(),
    }).catch(errorSnackbar);

    setClocks([...payload, ...clocks]);

    if (
      delayPrompt.isActive &&
      parse(delayPrompt.time, 'HH:mm:ss', new Date()) < new Date()
    ) {
      setOpen(true);
    }
  };

  return (
    <>
      <Button
        onClick={onClickStartNormalButton}
        variant="outlined"
        color="primary"
        fullWidth
      >
        上班
      </Button>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
        <DialogTitle>提醒</DialogTitle>
        <DialogContent>
          <DialogContentText>遲到請填寫請假卡</DialogContentText>
          <DialogContentText>並於三日內完成請假程序</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            了解
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

StartNormalButton.propTypes = {
  userId: PropTypes.string.isRequired,
  clocks: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
  setClocks: PropTypes.func.isRequired,
};

export default StartNormalButton;
