import { fetcher } from './helpers/fetch';

/**
 *
 * @param {Object} param
 * @param {string} param.sendTimesheetEmailCronTime send timesheet email
 * @param {string} param.sendDailyPlanEmailCronTime send daily plan email
 * @param {string=} param.bearerToken
 * @return {Promise}
 */
export const patchSettings = ({
  sendTimesheetEmailCronTime,
  sendDailyPlanEmailCronTime,
  bearerToken,
}) =>
  fetcher('api/settings', {
    method: 'PATCH',
    body: JSON.stringify({
      sendTimesheetEmailCronTime,
      sendDailyPlanEmailCronTime,
    }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: bearerToken,
    },
  });

/**
 * @param {{ bearerToken?: string }}
 * @return {Promise}
 */
export const getSettings = ({ bearerToken } = {}) =>
  fetcher(`api/settings`, {
    headers: { Authorization: bearerToken },
  });

/**
 * @param {Object} param
 * @param {string} param.company
 * @return {Promise}
 * @example
 * {
 *   "useOvertimeFeature": false,
 *   "tasks": [],
 *   "lunchBreak": {
 *     "start": {
 *       "hour": 12,
 *       "minute": 0
 *     },
 *     "end": {
 *       "hour": 13,
 *       "minute": 30
 *     }
 *   },
 *   "allowPhoneClockIn": false
 * }
 * */
export const getPublicSettings = ({ company, bearerToken }) =>
  fetcher('api/public-settings', {
    method: 'POST',
    body: JSON.stringify({
      company,
    }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: bearerToken,
    },
  });

export default {
  getSettings,
};
