import { Button } from '@material-ui/core';
import { endOfToday, startOfToday } from 'date-fns';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { initTimesheet } from 'helpers/timesheet';
import { postClocks } from '../../api/clocks';
import { getMoods } from '../../api/moods';
import { getTimesheets } from '../../api/timesheets';
import ConnectedTimesheet from '../../components/Timesheet';
import { checkNormalHour } from '../../helpers/bindClockAndTimesheet';
import AlertDialog from './AlertDialog';
import MoodRating from './MoodRating';

function EndNormalButton({ userId, clocks, setClocks, remote }) {
  const [open, setOpen] = useState(false);
  const [timesheets, setTimesheets] = useState([]);
  const lunchBreak = useSelector(({ setting }) => setting.lunchBreak);

  const { enqueueSnackbar } = useSnackbar();

  /**
   * 1. get timesheets
   * 2. if empty add one row
   * 3. open dialog
   */
  const onClickNormalEnd = async () => {
    const oneDayTimesheets = await getTimesheets({
      userId,
      startDate: startOfToday(),
      endDate: endOfToday(),
    });

    setTimesheets(
      isEmpty(oneDayTimesheets) ? [initTimesheet()] : oneDayTimesheets
    );
    setOpen(true);
  };

  /**
   * 1. get timesheets and moods
   * 2. 檢查 mood 有沒有寫，沒有不給打卡
   * 3. 檢查有沒有上班卡
   *      沒有的話，就不檢查了，給打卡
   *      有的話，時數一致，給打卡
   */
  const onClickAction = async () => {
    const [oneDayTimesheets, moods] = await Promise.all([
      getTimesheets({
        userId,
        startDate: startOfToday(),
        endDate: endOfToday(),
      }),
      getMoods({
        userId,
        startDate: startOfToday(),
        endDate: endOfToday(),
      }),
    ]);

    if (isEmpty(moods)) {
      enqueueSnackbar(`請選擇今日心情`, { variant: 'info' });
      return;
    }

    // 當天第一次打上班卡為準。
    const startNormal = [...clocks]
      .reverse()
      .find(({ type }) => type === '上班');

    // 如果沒打上班卡，還是可以打下班卡。
    if (startNormal !== undefined) {
      const addNormalEndClocks = [
        { type: '下班', date: new Date() },
        ...clocks,
      ];

      if (!checkNormalHour(addNormalEndClocks, oneDayTimesheets, lunchBreak)) {
        enqueueSnackbar(
          `請確認有無按 Timesheet 的上傳按鈕或是檢查時數是否一致`,
          {
            variant: 'info',
          }
        );
        return;
      }
    }

    const payload = await postClocks({ type: '下班', userId, remote });

    setOpen(false);
    setClocks([...payload, ...clocks]);
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        fullWidth
        onClick={onClickNormalEnd}
      >
        {remote ? '遠端下班' : '下班'}
      </Button>
      <AlertDialog
        open={open}
        setOpen={setOpen}
        onClickAction={onClickAction}
        actionName="下班"
      >
        <MoodRating userId={userId} date={startOfToday()} />
        <br />
        <ConnectedTimesheet
          date={startOfToday()}
          userId={userId}
          timesheets={timesheets}
          clocks={[{ type: '下班', date: new Date().toISOString() }, ...clocks]}
        />
      </AlertDialog>
    </>
  );
}

EndNormalButton.propTypes = {
  userId: PropTypes.string.isRequired,
  clocks: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      type: PropTypes.string,
    })
  ).isRequired,
  setClocks: PropTypes.func.isRequired,
  remote: PropTypes.bool.isRequired,
};

export default EndNormalButton;
