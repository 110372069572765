import { fetcher } from './helpers/fetch';

/**
 *
 * @param {Object} param
 * @param {Array} param.timesheets
 * @param {string=} param.bearerToken
 * @return {Promise}
 */
export const postTimesheets = ({ timesheets, bearerToken }) =>
  fetcher(`api/timesheets`, {
    method: 'POST',
    body: JSON.stringify(timesheets),
    headers: {
      'Content-Type': 'application/json',
      Authorization: bearerToken,
    },
  });

/**
 *
 * @param {Object} param
 * @param {Date} param.startDate
 * @param {Date} param.endDate
 * @param {string=} param.userId
 * @param {string=} param.projectId
 * @param {string=} param.bearerToken
 * @return {Promise}
 */
export const getTimesheets = ({
  startDate,
  endDate,
  userId,
  projectId,
  bearerToken,
}) => {
  const startISODate = startDate.toISOString();
  const endISODate = endDate.toISOString();

  let query = `startDate=${startISODate}&endDate=${endISODate}`;

  if (userId) {
    query += `&userId=${userId}`;
  }

  if (projectId) {
    query += `&projectId=${projectId}`;
  }

  return fetcher(`api/timesheets?${query}`, {
    headers: { Authorization: bearerToken },
  });
};

/**
 *
 * @param {Object} param
 * @param {Date} param.startDate
 * @param {Date} param.endDate
 * @param {Array} param.timesheets
 * @param {string=} param.bearerToken
 * @return {Promise}
 */
export const putTimesheets = ({
  startDate,
  endDate,
  timesheets,
  bearerToken,
}) => {
  const startISODate = startDate.toISOString();
  const endISODate = endDate.toISOString();

  return fetcher(
    `api/timesheets?startDate=${startISODate}&endDate=${endISODate}`,
    {
      method: 'PUT',
      body: JSON.stringify(timesheets),
      headers: {
        'Content-Type': 'application/json',
        Authorization: bearerToken,
      },
    }
  );
};
