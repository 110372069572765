import {
  Button,
  Grid,
  Hidden,
  Link as MaterialLink,
  TextField,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { getHours } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getPublicImage } from '../../api/publicImage';
import { signInActionCreator } from '../../ducks/account';
import { fetchPublicSetting } from '../../ducks/setting';
import { noLogErrorMessage } from '../../helpers/fetch';
import { getItem } from '../../helpers/localStorage';
import useSnackbar from '../../hooks/useSnackbar';
import defaultBulletinAM from './defaultBulletin-am.png';
import defaultBulletinPM from './defaultBulletin-pm.png';
import defaultLogo from './defaultLogo.png';
import style from './index.module.css';

/**
 * SignIn
 * - 自動轉大寫
 * - 輸入錯誤會進不去
 * - 可以按 enter 登入
 *
 * 不管用大寫小寫，感覺都可以，這裡統一一種格式就好。
 */
export function SignIn({
  signIn,
  history,
  allowPhoneClockIn,
  getPublicSetting,
}) {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [logoImg, setLogoImg] = useState('');
  const [bulletinImg, setBulletinImg] = useState('');

  const { errorSnackbar } = useSnackbar();
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    if (!getItem('company')) history.push('/signin-company');

    const company = getItem('company');

    getPublicSetting({ company });

    getPublicImage({ company, filename: 'logo' })
      .then((url) => setLogoImg(url))
      .catch(noLogErrorMessage);

    getPublicImage({
      company,
      filename: `bulletin/${getHours(new Date())}`,
    })
      .then((url) => setBulletinImg(url))
      .catch(noLogErrorMessage);
  }, [history, getPublicSetting]);

  const onChange = (e) => setUserName(e.target.value.toUpperCase());

  /**
   * use form to more semantic but need to pass e.preventDefault();
   * instead of below line.
   * const onKeyPress = (e) => e.key === 'Enter' && signIn();
   */
  const onSubmit = (e) => {
    e.preventDefault();

    const company = getItem('company');

    // 大螢幕送去打卡頁面
    // 小螢幕送去上傳圖片頁面
    if (isPhone && !allowPhoneClockIn) {
      return signIn(
        { userName, password, company },
        () => history.push('/daily-plan'),
        errorSnackbar
      );
    }

    return signIn(
      { userName, password, company },
      () => history.push('/clock'),
      errorSnackbar
    );
  };

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={0}>
        {/* 小螢幕不顯示 bulletin */}
        <Hidden mdDown>
          <Grid item>
            <img
              src={bulletinImg}
              className={style.bulletinImg}
              alt="bulletin"
              onError={() => {
                const hour = getHours(new Date());
                if (hour > 6 && hour < 16) {
                  setBulletinImg(defaultBulletinAM);
                } else {
                  setBulletinImg(defaultBulletinPM);
                }
              }}
            />
          </Grid>
        </Hidden>
        <Grid
          item
          container
          spacing={5}
          justifyContent="center"
          alignContent="center"
          classes={{ root: style.form }}
        >
          <Grid item xs={12}>
            <img
              src={logoImg}
              alt="logo"
              className={style.logoImg}
              onError={() => setLogoImg(defaultLogo)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="User Name"
              id="userName"
              autoFocus={!isPhone}
              value={userName}
              onChange={onChange}
              fullWidth
              inputProps={{ className: style.TextField }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Password"
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              inputProps={{ className: style.TextField }}
            />
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <Tooltip title="登入 (Enter)">
              <Button type="submit" variant="outlined">
                Sign In
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <MaterialLink
              component={Link}
              to="/signin-company"
              underline="always"
            >
              Sign in with another company
            </MaterialLink>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

SignIn.propTypes = {
  signIn: PropTypes.func.isRequired,
  getPublicSetting: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  allowPhoneClockIn: PropTypes.bool.isRequired,
};

const mapState = ({ setting }) => ({
  allowPhoneClockIn: setting.allowPhoneClockIn,
});

export default connect(mapState, {
  signIn: signInActionCreator,
  getPublicSetting: fetchPublicSetting,
})(SignIn);
