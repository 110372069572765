import { getISODate } from './helpers/date';
import { fetcher } from './helpers/fetch';

/**
 * https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
 * @param {Object} param
 * @param {Buffer} param.file
 * @param {Date=} param.date
 * @param {string=} param.bearerToken
 * @return {Promise}
 */
export const postDailyPlan = ({ file, date, bearerToken }) => {
  const ISODate = getISODate(date);

  const formData = new FormData();
  formData.append('dailyPlan', file);
  formData.append('date', ISODate);

  return fetcher('api/daily-plan', {
    method: 'POST',
    body: formData,
    headers: { Authorization: bearerToken },
  });
};

/**
 *
 * @param {Object} param
 * @param {string} param.userId
 * @param {Date | string} [param.date]
 * @param {number} [param.expireSecond]
 * @param {boolean} [param.checkFileExist=false]
 * @param {string=} param.bearerToken
 * @return {Promise}
 */
export const getDailyPlan = ({
  userId,
  date,
  expireSecond,
  checkFileExist = false,
  bearerToken,
}) => {
  const ISODate = getISODate(date);

  let query = `userId=${userId}&date=${ISODate}`;
  if (expireSecond) {
    query += `&expireSecond=${expireSecond}`;
  }
  if (checkFileExist) {
    query += '&checkFileExist=true';
  }

  return fetcher(`api/daily-plan?${query}`, {
    headers: { Authorization: bearerToken },
  });
};
