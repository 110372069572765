import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './ducks';
import Theme from './pages/Theme';
import reportWebVitals from './reportWebVitals';

const store = configureStore();

ReactDOM.render(
  /**
   * https://github.com/facebook/react/issues/15074
   * useState render twice only in development
   */
  <StrictMode>
    <Provider store={store}>
      <Theme />
    </Provider>
  </StrictMode>,
  // eslint-disable-next-line no-undef
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
