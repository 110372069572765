import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Tooltip,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

function AlertDialog({ open, setOpen, children, onClickAction, actionName }) {
  const onClose = () => setOpen(false);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Tooltip title="記得要先按 Timesheet 的上傳喔">
          <Button variant="outlined" onClick={onClickAction}>
            {actionName}
          </Button>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
}

AlertDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  onClickAction: PropTypes.func.isRequired,
  actionName: PropTypes.string.isRequired,
};

export default AlertDialog;
