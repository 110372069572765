import { isEmpty } from 'lodash';
import { fetcher } from './helpers/fetch';

/**
 *
 * @param {Object} param
 * @param {string} param.userId
 * @param {string} param.userName
 * @param {string} param.role
 * @param {string} param.password
 * @param {string=} param.email
 * @param {string=} param.salary
 * @param {string=} param.bearerToken
 * @return {Promise}
 */
export const postUsers = ({
  userId,
  userName,
  role,
  password,
  email,
  salary,
  bearerToken,
}) =>
  fetcher('api/users', {
    method: 'POST',
    body: JSON.stringify([{ userId, userName, role, password, email, salary }]),
    headers: {
      'Content-Type': 'application/json',
      Authorization: bearerToken,
    },
  });

/**
 * @param {{roles?: [string], bearerToken?: string, sort?: string }}
 * @return {Promise}
 */
export const getUsers = ({ roles, bearerToken, sort } = {}) => {
  if (roles && !Array.isArray(roles)) {
    return new Error('Content Error: roles should be array.');
  }

  let query = '';

  if (!isEmpty(roles)) {
    query += `?role=${roles.join('&role=')}`;
  }

  if (sort) {
    query += `&sort=${sort}`;
  }

  return fetcher(`api/users${query}`, {
    headers: { Authorization: bearerToken },
  });
};

/**
 *
 * @param {Object} param
 * @param {string} param.userId
 * @param {string=} param.userName
 * @param {string=} param.role
 * @param {string=} param.password
 * @param {string=} param.email
 * @param {string=} param.salary
 * @param {string=} param.bearerToken
 * @return {Promise}
 */
export const patchUsers = async ({
  userId,
  userName,
  role,
  password,
  email,
  salary,
  bearerToken,
}) => {
  const payload = { userId };
  if (userName != null) payload.userName = userName;
  if (role != null) payload.role = role;
  if (password != null) payload.password = password;
  if (email != null) payload.email = email;
  if (salary != null) payload.salary = salary;

  return fetcher('api/users', {
    method: 'PATCH',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      Authorization: bearerToken,
    },
  });
};

/**
 *
 * @param {{ userId: string, bearerToken?: string }}
 * @return {Promise}
 */
export const deleteUsers = ({ userId, bearerToken }) =>
  fetcher(`api/users?userId=${userId}`, {
    method: 'DELETE',
    headers: {
      Authorization: bearerToken,
    },
  });
