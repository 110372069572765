import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import PreFillTimesheet from './PreFillTimesheet';
import style from './PreFillTimesheets.module.css';

function PreFillTimesheets({ setTimesheets, userId, timesheets }) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={1} />
      <Grid item xs={3}>
        <Typography paragraph classes={style}>
          專案
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography paragraph classes={style}>
          項目
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography paragraph classes={style}>
          內容
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography paragraph classes={style} align="center">
          0.5hr
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography paragraph classes={style} align="center">
          1.0hr
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography paragraph classes={style} align="center">
          1.5hr
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography paragraph classes={style} align="center">
          2.0hr
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography paragraph classes={style} align="center">
          3.0hr
        </Typography>
      </Grid>
      {timesheets.map((v, index) => (
        <PreFillTimesheet
          // eslint-disable-next-line no-underscore-dangle
          key={v._id}
          index={index}
          setTimesheets={setTimesheets}
          userId={userId}
          timesheet={v}
        />
      ))}
    </Grid>
  );
}

PreFillTimesheets.propTypes = {
  setTimesheets: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  timesheets: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      projectId: PropTypes.string,
      task: PropTypes.string,
      remark: PropTypes.string,
      normalHour: PropTypes.number,
      overTime: PropTypes.number,
    })
  ).isRequired,
};

export default PreFillTimesheets;
