import { fetcher } from './helpers/fetch';

/**
 *
 * @param {Object} param
 * @param {string} param.projectId
 * @param {string} param.projectName
 * @param {number=} param.projectLimit
 * @param {boolean=} param.projectActive
 * @param {boolean=} param.projectOvertimeActive
 * @param {string=} param.bearerToken
 * @return {Promise}
 */
export const postProject = ({
  projectId,
  projectName,
  projectLimit = 0,
  projectActive = true,
  projectOvertimeActive = true,
  bearerToken,
}) =>
  fetcher('api/projects', {
    method: 'POST',
    body: JSON.stringify([
      {
        projectId,
        projectName,
        projectLimit,
        projectActive,
        projectOvertimeActive,
      },
    ]),
    headers: {
      'Content-Type': 'application/json',
      Authorization: bearerToken,
    },
  });

/**
 * @param {{bearerToken?: string }}
 * @return {Promise}
 */
export const getProjects = ({ bearerToken } = {}) =>
  fetcher('api/projects', {
    headers: { Authorization: bearerToken },
  });

/**
 *
 * @param {Object} param
 * @param {string} param.projectId
 * @param {string} param.projectName
 * @param {number} param.projectLimit
 * @param {boolean} param.projectActive
 * @param {boolean} param.projectOvertimeActive
 * @param {string=} param.bearerToken
 * @return {Promise}
 */
export const patchProject = ({
  projectId,
  projectName,
  projectLimit,
  projectActive,
  projectOvertimeActive,
  bearerToken,
}) => {
  const payload = { projectId };
  if (projectName !== undefined) payload.projectName = projectName;
  if (projectLimit !== undefined) payload.projectLimit = projectLimit;
  if (projectActive !== undefined) payload.projectActive = projectActive;
  if (projectOvertimeActive !== undefined)
    payload.projectOvertimeActive = projectOvertimeActive;

  return fetcher('api/projects', {
    method: 'PATCH',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      Authorization: bearerToken,
    },
  });
};

/**
 *
 * @param {{ projectId: string, bearerToken?: string}}
 * @return {Promise}
 */
export const deleteProject = ({ projectId, bearerToken }) =>
  fetcher(`api/projects?projectId=${projectId}`, {
    method: 'DELETE',
    headers: {
      Authorization: bearerToken,
    },
  });
