import { fetcher } from './helpers/fetch';

/**
 * @param {{ bearerToken?: string }}
 * @returns {Promise}
 */
export const getCompanies = ({ bearerToken } = {}) =>
  fetcher(`api/companies`, {
    headers: { Authorization: bearerToken },
  });

export default {
  getCompanies,
};
