import { calNormalHourByClocks, calOverTimeHourByClocks } from './clock';
import { reduceHourByType } from './timesheet';

export const checkNormalHour = (clocks, timesheets, lunchBreak) => {
  const clockHour = calNormalHourByClocks(clocks, lunchBreak);

  const timesheetHour = reduceHourByType(timesheets, 'normalHour');

  return Math.abs(clockHour - timesheetHour) <= 0.9;
};

export const checkOverTimeHour = (clocks, timesheets) => {
  const clockHour = calOverTimeHourByClocks(clocks);

  const timesheetHour = reduceHourByType(timesheets, 'overTime');

  return Math.abs(clockHour - timesheetHour) <= 0.9;
};
