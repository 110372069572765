import { getPublicSettings, getSettings } from '../api/settings';
import { error } from '../helpers/fetch';
import { SIGN_OUT } from './account';
// Actions
export const GET = 'setting/GET';
export const PUBLIC_GET = 'setting/PUBLIC_GET';

export const initState = {
  useOvertimeFeature: false,
  tasks: [],
  lunchBreak: {
    start: {
      hour: 12,
      minute: 0,
    },
    end: {
      hour: 13,
      minute: 30,
    },
  },
  allowPhoneClockIn: false,
  delayPrompt: {
    isActive: { type: Boolean, default: false },
    time: { type: String, default: '08:41:00' },
  },
};

// Reducer
export default (state = initState, { type, payload } = {}) => {
  switch (type) {
    case GET:
      /**
       * https://redux.js.org/style-guide/style-guide#reducers-should-own-the-state-shape
       * violate Reducers Should Own the State Shape
       */
      return payload;

    case PUBLIC_GET:
      return { ...state, ...payload };

    case SIGN_OUT:
      return initState;

    default:
      return state;
  }
};

// Action Creators
export const fetchSetting = () => (dispatch) =>
  getSettings()
    .then((payload) =>
      dispatch({
        type: GET,
        payload,
      })
    )
    .catch(error);

export const fetchPublicSetting = ({ company }) => (dispatch) =>
  getPublicSettings({ company })
    .then((payload) =>
      dispatch({
        type: PUBLIC_GET,
        payload,
      })
    )
    .catch(error);
