const reportWebVitals = (onPerfEntry) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      // Cumulative Layout Shift (CLS): measures "visual stability". To provide a good user experience, pages should maintain a CLS of less than 0.1.
      getCLS(onPerfEntry);

      // First Input Delay (FID): measures "interactivity". To provide a good user experience, pages should have a FID of less than 100 milliseconds.
      getFID(onPerfEntry);

      getFCP(onPerfEntry);

      // Largest Contentful Paint (LCP): measures "loading" performance. To provide a good user experience, LCP should occur within 2.5 seconds of when the page first starts loading.
      getLCP(onPerfEntry);

      getTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;
