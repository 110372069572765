export const auth = { token: null };

/**
 * 設置全局的 auth token
 * 為了提升更好的可讀性，更改名稱 setToken => setAuthToken
 * https://daveceddia.com/access-redux-store-outside-react
 * @param {string} token
 */
export const setAuthToken = (token) => {
  auth.token = token;
};
