import { IconButton } from '@material-ui/core';
import { VerticalAlignTop } from '@material-ui/icons';
import React from 'react';
import style from './GoToTop.module.css';

function GoToTop() {
  const onClickTop = () => window.scroll(0, 0);
  return (
    <IconButton aria-label="top" className={style.button} onClick={onClickTop}>
      <VerticalAlignTop />
    </IconButton>
  );
}

export default GoToTop;
