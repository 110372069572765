import {
  Button,
  ButtonGroup,
  Grid,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { deleteUsers, patchUsers } from '../../api/users';
import DeleteDialog from '../../components/DeleteDialog';
import useSnackbar from '../../hooks/useSnackbar';

function User({ userId, userName, role, email, fetchUsers }) {
  const [user, setUser] = useState({
    userId,
    userName,
    role,
    password: '',
    email,
  });
  const [open, setOpen] = useState(false);

  const { successSnackbar, errorSnackbar } = useSnackbar();

  const onUpdate = () => {
    const updatedUser = { ...user };
    if (updatedUser.password === '') {
      delete updatedUser.password;
    }
    patchUsers(updatedUser)
      .then(successSnackbar)
      .then(fetchUsers)
      .catch(errorSnackbar);
  };

  const onDelete = () => {
    deleteUsers({ userId })
      .then(successSnackbar)
      .then(fetchUsers)
      .catch(errorSnackbar);
  };

  return (
    <>
      <Grid item sm={2} xs={12}>
        <TextField type="text" value={user.userId} disabled fullWidth />
      </Grid>
      <Grid item sm={2} xs={12}>
        <TextField
          type="text"
          value={user.userName}
          onChange={(e) => setUser({ ...user, userName: e.target.value })}
          fullWidth
        />
      </Grid>
      <Grid item sm={2} xs={12}>
        <Select
          value={user.role}
          onChange={(e) => setUser({ ...user, role: e.target.value })}
          fullWidth
        >
          <MenuItem value="ADMIN">ADMIN</MenuItem>
          <MenuItem value="KEEPER">KEEPER</MenuItem>
          <MenuItem value="STAFF">STAFF</MenuItem>
          <MenuItem value="INTERN">INTERN</MenuItem>
          <MenuItem value="INACTIVE">INACTIVE</MenuItem>
        </Select>
      </Grid>
      <Grid item sm={2} xs={12}>
        <Tooltip title="重設密碼">
          <TextField
            type="text"
            value={user.password}
            onChange={(e) => setUser({ ...user, password: e.target.value })}
            fullWidth
          />
        </Tooltip>
      </Grid>
      <Grid item sm={2} xs={12}>
        <TextField
          type="text"
          value={user.email}
          onChange={(e) => setUser({ ...user, email: e.target.value })}
          fullWidth
        />
      </Grid>
      <Grid item xs="auto">
        <ButtonGroup>
          <Button onClick={onUpdate}>Update</Button>
          <Button onClick={() => setOpen(true)}>Delete</Button>
        </ButtonGroup>
      </Grid>
      <Grid item xs={12} />
      <DeleteDialog
        onDelete={onDelete}
        open={open}
        setOpen={setOpen}
        content={`如果 Timesheet 資料中有 ${userId} - ${userName}，刪除他會造成以後抓不到此資料的資訊喔。`}
      />
    </>
  );
}

User.defaultProps = {
  email: '',
};

User.propTypes = {
  userId: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  email: PropTypes.string,
  fetchUsers: PropTypes.func.isRequired,
};

export default React.memo(User);
