import { keyBy } from 'lodash';
import { getProjects } from '../api/projects';
import { SIGN_OUT } from './account';

// Actions
export const GET = 'projects/GET';

// Reducer
export default (state = [], { type, payload } = {}) => {
  switch (type) {
    case GET:
      /**
       * https://redux.js.org/style-guide/style-guide#reducers-should-own-the-state-shape
       * violate Reducers Should Own the State Shape
       */
      return payload;

    case SIGN_OUT:
      return [];

    default:
      return state;
  }
};

export const projectsV2 = (state = [], { type, payload } = {}) => {
  switch (type) {
    case GET: {
      const payloadHash = keyBy(payload, 'projectId');
      /**
       * https://redux.js.org/style-guide/style-guide#reducers-should-own-the-state-shape
       * violate Reducers Should Own the State Shape
       */
      return [payload, payloadHash];
    }

    case SIGN_OUT:
      return [];

    default:
      return state;
  }
};

/**
 * Action Creators
 *
 * 1. dispatch({ type: GET, payload });
 */
export const fetchProjects = () => (dispatch) =>
  getProjects().then((payload) => dispatch({ type: GET, payload }));
