import { useSnackbar as useNotistackSnackbar } from 'notistack';

export default () => {
  const { enqueueSnackbar } = useNotistackSnackbar();

  return {
    savingSnackbar: () => enqueueSnackbar('Saving...'),
    savedSnackbar: () => enqueueSnackbar('Saved'),
    successSnackbar: () => enqueueSnackbar('Success', { variant: 'success' }),
    infoSnackbar: (info, options) =>
      enqueueSnackbar(`${info}`, { variant: 'info', ...options }),
    warningSnackbar: (warningMessage, options) =>
      enqueueSnackbar(`${warningMessage}`, { variant: 'warning', ...options }),
    errorSnackbar: (err, options) => {
      if (typeof err === 'string' || err instanceof String) {
        return enqueueSnackbar(err, { variant: 'error', ...options });
      }

      if (err instanceof Error) {
        enqueueSnackbar(`${err}`, { variant: 'error', ...options });
      }

      return enqueueSnackbar('Error', { variant: 'error', ...options });
    },
  };
};
