import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { getItem, setItem } from 'helpers/localStorage';
import { keyBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getActiveProjects, getProjectsHash } from './projectSelectors';

function ProjectAutocomplete({
  projectId: prevProjectId,
  setProjectId,
  filterProjectOvertimeActive,
}) {
  const projects = useSelector(getActiveProjects);
  const projectsHash = useSelector(getProjectsHash);
  const [sortedProjects, setSortedProjects] = useState(projects);

  useEffect(() => {
    const recentProjects = getItem('recentProjects') || [];

    const activeProjects = recentProjects
      // 確保 project 一定是最新的狀態
      .map((project) => projectsHash?.[project?.projectId])
      // 刪掉在雲端已經被刪掉的或是 inactive 的項目
      .filter((project) => {
        if (filterProjectOvertimeActive)
          return project?.projectActive && project?.projectOvertimeActive;
        return project?.projectActive;
      });

    const activeProjectsHash = keyBy(activeProjects, 'projectId');

    // 已經排到前面的，後面不要重複
    const restProjects = projects.filter(
      ({ projectId, projectOvertimeActive }) => {
        if (filterProjectOvertimeActive) {
          return !activeProjectsHash[projectId] && projectOvertimeActive;
        }
        return !activeProjectsHash[projectId];
      }
    );

    setSortedProjects([...activeProjects, ...restProjects]);
  }, [projects, projectsHash, filterProjectOvertimeActive]);

  const onChangeProject = (e, project) => {
    setProjectId(project && project.projectId);

    if (!project) return;

    // 得到最近用的 project
    let recentProjects = getItem('recentProjects') || [];

    // 刪掉新增的(避免重複)
    const removedLatestProjects = recentProjects.filter(
      (v) => v.projectId !== project.projectId
    );

    // 加上新增的到最前面
    // 最多只有 7 個
    recentProjects = [project, ...removedLatestProjects].slice(0, 7);

    const recentProjectsHash = keyBy(recentProjects, 'projectId');

    // 已經排到前面的，後面不要重複
    const restProjects = projects.filter(
      (v) => !recentProjectsHash[v.projectId]
    );

    setItem('recentProjects', recentProjects);
    setSortedProjects([...recentProjects, ...restProjects]);
  };

  let selectProject;
  if (prevProjectId === null) {
    selectProject = null;
  } else if (projectsHash?.[prevProjectId]) {
    selectProject = projectsHash[prevProjectId];
  } else {
    selectProject = {
      projectId: prevProjectId,
      // 考慮將來可以儲存 projectName 在 timesheet 中，這樣就不用太過於依賴專案頁面
      projectName: '該專案已被刪除',
    };
  }

  return (
    <Autocomplete
      name="project"
      data-testid="project-autocomplete"
      options={sortedProjects}
      onChange={onChangeProject}
      getOptionLabel={(v) => `${v.projectId} - ${v.projectName}`}
      getOptionSelected={(option, value) =>
        option?.projectId === value?.projectId
      }
      value={selectProject}
      autoHighlight
      autoSelect
      noOptionsText="請先在 PROJECTS 頁面增加專案"
      // eslint-disable-next-line react/jsx-props-no-spreading
      renderInput={(props) => <TextField {...props} />}
      size="small"
    />
  );
}

ProjectAutocomplete.propTypes = {
  projectId: PropTypes.string,
  setProjectId: PropTypes.func.isRequired,
  filterProjectOvertimeActive: PropTypes.bool,
};

ProjectAutocomplete.defaultProps = {
  projectId: null,
  filterProjectOvertimeActive: false,
};

export default ProjectAutocomplete;
