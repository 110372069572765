// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
// function storageAvailable(type) {
//   let storage;
//   try {
//     storage = window[type];
//     const x = '__storage_test__';
//     storage.setItem(x, x);
//     storage.removeItem(x);
//     return true;
//   } catch (e) {
// return e instanceof DOMException && (
//   // everything except Firefox
//   e.code === 22
//   // Firefox
//   || e.code === 1014
//   // test name field too, because code might not be present
//   // everything except Firefox
//   || e.name === 'QuotaExceededError'
//   // Firefox
//   || e.name === 'NS_ERROR_DOM_QUOTA_REACHED')
//   // acknowledge QuotaExceededError only if there's something already stored
//   && (storage && storage.length !== 0);
//   }
// }

// only exec once
const isAvailable = (function isAvailableIffe() {
  const x = '__storage_test__';
  try {
    localStorage.setItem(x, x);
    localStorage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      localStorage &&
      localStorage.length !== 0
    );
  }
})();

export const getItem = (key) => {
  if (isAvailable) {
    const serialized = localStorage.getItem(key);
    if (serialized === null) {
      return undefined;
    }
    return JSON.parse(serialized);
  }
  return undefined;
};

export const setItem = (key, value) => {
  if (isAvailable) {
    const serialized = JSON.stringify(value);
    localStorage.setItem(key, serialized);
  }
  // Ignore write errors.
};

export const loadState = () => getItem('state');

export const saveState = (state) => setItem('state', state);
