import { getUsers } from '../api/users';
import { error } from '../helpers/fetch';
import { SIGN_OUT } from './account';
// Actions
export const GET = 'users/GET';

// Reducer
export default (state = [], { type, payload } = {}) => {
  switch (type) {
    case GET:
      /**
       * https://redux.js.org/style-guide/style-guide#reducers-should-own-the-state-shape
       * violate Reducers Should Own the State Shape
       */
      return payload;

    case SIGN_OUT:
      return [];

    default:
      return state;
  }
};

/**
 * Action Creators
 *
 * 1. dispatch({ type: GET, payload });
 * 2. reduce to userId and userName
 */
export const fetchUsers = () => (dispatch) =>
  getUsers()
    .then((payload) =>
      dispatch({
        type: GET,
        payload,
      })
    )
    .catch(error);
