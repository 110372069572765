import { startOfDay } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { getDailyPlan } from '../../api/dailyPlan';
import { noLogErrorMessage } from '../../helpers/fetch';
import style from './index.module.css';

function TaskImage({ userId, date }) {
  const [imgURL, setImgURL] = useState('');

  useEffect(() => {
    getDailyPlan({ userId, date: startOfDay(date) })
      .then(setImgURL)
      .catch(noLogErrorMessage);
  }, [userId, date]);

  return (
    imgURL && (
      <img
        src={imgURL}
        alt={userId}
        className={style.img}
        onError={() => setImgURL('')}
      />
    )
  );
}

TaskImage.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  userId: PropTypes.string.isRequired,
};

export default TaskImage;
