import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { getItem, setItem } from 'helpers/localStorage';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSetting } from '../../ducks/setting';

function TaskAutocomplete({ task: prevTask, setTask }) {
  const tasks = useSelector(({ setting }) => setting.tasks);
  const [sortedTasks, setSortedTasks] = useState(tasks);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSetting());
    // Safe to add dispatch to the dependencies array
  }, [dispatch]);

  useEffect(() => {
    const recentTasks = getItem('recentTasks') || [];

    setSortedTasks([...recentTasks, ...tasks]);
  }, [tasks]);

  const onChangeTask = (e, task) => {
    setTask(task);

    if (!task) return;

    // 得到最近用的 Task
    let recentTasks = getItem('recentTasks') || [];

    // 刪掉新增的(避免重複)
    const removedLatestTasks = recentTasks.filter((v) => v !== task);

    // 加上新增的到最前面
    // 最多只有 7 個
    recentTasks = [task, ...removedLatestTasks].slice(0, 7);

    setItem('recentTasks', recentTasks);
    setSortedTasks([...recentTasks, ...tasks]);
  };

  return (
    <Autocomplete
      name="task"
      data-testid="task-autocomplete"
      options={sortedTasks}
      onChange={onChangeTask}
      value={prevTask}
      autoHighlight
      autoSelect
      // eslint-disable-next-line react/jsx-props-no-spreading
      renderInput={(props) => <TextField {...props} />}
      size="small"
    />
  );
}

TaskAutocomplete.propTypes = {
  task: PropTypes.string,
  setTask: PropTypes.func.isRequired,
};

TaskAutocomplete.defaultProps = {
  task: null,
};

export default TaskAutocomplete;
