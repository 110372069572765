import { initTimesheet } from 'helpers/timesheet';

export const PUT = 'timesheetReducer/PUT';
export const GET = 'timesheetReducer/GET';
export const ADD_ROW = 'timesheetReducer/ADD_ROW';
export const ADD_ROW_AFTER_INDEX = 'timesheetReducer/ADD_ROW_AFTER_INDEX';
export const DELETE_INDEX_ROW = 'timesheetReducer/DELETE_INDEX_ROW';
export const DELETE_ROW = 'timesheetReducer/DELETE_ROW';

// Common Redux misconception: you need to deeply clone the state.
// Reality: if something inside doesn't change, keep its reference the same!
// reducer 會比較前後 object 有變化才 render，所以不能直接改 state
export default (state, { type, payload }) => {
  switch (type) {
    case PUT:
      /**
       * https://immerjs.github.io/immer/docs/curried-produce
       * if use produce:
       *    return state[payload.index][payload.name] = payload.value
       * is simple and intuition
       * but because of performance consider, use vanilla js
       */
      return state.map(
        // only update index, others keep same
        (value, index) =>
          index !== payload.index
            ? value
            : { ...value, [payload.name]: payload.value }
      );

    case ADD_ROW:
      return [...state, initTimesheet()];

    case ADD_ROW_AFTER_INDEX: {
      const newState = [...state];
      newState.splice(payload.index + 1, 0, initTimesheet());
      return newState;
    }
    case DELETE_INDEX_ROW: {
      const newState = [...state];
      newState.splice(payload.index, 1);
      return newState;
    }

    case DELETE_ROW:
      return state.slice(0, -1);

    case GET:
      /**
       * https://stackoverflow.com/questions/7837456/how-to-compare-arrays-in-javascript
       * usually use in scalar array
       * simple and almost fast method
       * _.isEqual(state, payload): 最簡單
       * JSON.stringify(state) === JSON.stringify(payload): 最快
       *
       * I don't know why I should compare between state and payload
       * just return payload
       *
       * https://redux.js.org/style-guide/style-guide#reducers-should-own-the-state-shape
       * violate Reducers Should Own the State Shape
       */
      return payload;

    default:
      throw new Error();
  }
};
