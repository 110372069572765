import {
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Switch,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { deleteProject, patchProject } from '../../api/projects';
import DeleteDialog from '../../components/DeleteDialog';
import { fetchProjects } from '../../ducks/projects';
import useSnackbar from '../../hooks/useSnackbar';

export function Project({
  projectId,
  projectName: initialProjectName,
  projectLimit: initialProjectLimit,
  projectActive: initialProjectActive,
  projectOvertimeActive: initialProjectOvertimeActive,
  fetchProjects: getProjects,
}) {
  const useOvertimeFeature = useSelector(
    ({ setting }) => setting.useOvertimeFeature
  );
  const [project, setProject] = useState({
    projectName: initialProjectName,
    projectLimit: initialProjectLimit,
    projectActive: initialProjectActive,
    projectOvertimeActive: initialProjectOvertimeActive,
  });
  const [open, setOpen] = useState(false);
  const { successSnackbar, errorSnackbar } = useSnackbar();

  useEffect(() => {
    setProject({
      projectName: initialProjectName,
      projectLimit: initialProjectLimit,
      projectActive: initialProjectActive,
      projectOvertimeActive: initialProjectOvertimeActive,
    });
  }, [
    initialProjectName,
    initialProjectLimit,
    initialProjectActive,
    initialProjectOvertimeActive,
  ]);

  const onDelete = () => {
    deleteProject({ projectId })
      .then(successSnackbar)
      .then(getProjects)
      .catch(errorSnackbar);
  };

  return (
    <>
      <Grid item xs={1}>
        <Tooltip title="是否開放給使用者選擇此專案">
          <Switch
            checked={project.projectActive}
            onChange={(e) => {
              const projectActive = e.target.checked;
              setProject((state) => ({ ...state, projectActive }));
              patchProject({ projectId, projectActive })
                .then(successSnackbar)
                .catch(errorSnackbar);
            }}
            name="active"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </Tooltip>
      </Grid>
      {useOvertimeFeature && (
        <Grid item xs={1}>
          <Tooltip title="此專案可否加班">
            <Switch
              checked={project.projectOvertimeActive}
              onChange={(e) => {
                const projectOvertimeActive = e.target.checked;
                setProject((state) => ({ ...state, projectOvertimeActive }));
                patchProject({ projectId, projectOvertimeActive })
                  .then(successSnackbar)
                  .catch(errorSnackbar);
              }}
              name="overtimeActive"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Tooltip>
        </Grid>
      )}
      <Grid item xs={2}>
        <TextField type="text" value={projectId} disabled fullWidth />
      </Grid>
      <Grid item xs={useOvertimeFeature ? 6 : 7}>
        <TextField
          type="text"
          value={project.projectName}
          onChange={(e) => {
            const projectName = e.target.value;
            setProject((state) => ({ ...state, projectName }));
            patchProject({ projectId, projectName })
              .then(successSnackbar)
              .catch(errorSnackbar);
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={1}>
        <Tooltip title="本月工時水位，若超過時數，Email 通知">
          <Input
            type="number"
            value={project.projectLimit}
            onChange={(e) => {
              const projectLimit = e.target.value;
              setProject((state) => ({ ...state, projectLimit }));
              patchProject({ projectId, projectLimit })
                .then(successSnackbar)
                .catch(errorSnackbar);
            }}
            fullWidth
            inputProps={{ style: { textAlign: 'center' } }}
            endAdornment={<InputAdornment position="start">hr</InputAdornment>}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={1}>
        <IconButton
          onClick={() => setOpen(true)}
          aria-label="delete"
          style={{ paddingTop: '6px' }}
        >
          <Delete />
        </IconButton>
        <DeleteDialog
          onDelete={onDelete}
          open={open}
          setOpen={setOpen}
          content={`如果 Timesheet 資料中有 ${projectId} - ${project.projectName}，刪除此專案會造成以後抓不到此專案的名稱。若不需此 Project，建議可用關閉給使用者選擇的方式。`}
        />
      </Grid>
    </>
  );
}

Project.propTypes = {
  projectId: PropTypes.string.isRequired,
  projectLimit: PropTypes.number.isRequired,
  projectName: PropTypes.string.isRequired,
  fetchProjects: PropTypes.func.isRequired,
  projectActive: PropTypes.bool.isRequired,
  projectOvertimeActive: PropTypes.bool.isRequired,
};

export default connect(null, { fetchProjects })(Project);
