import { signIn } from '../api/account';

// Actions
export const SIGN_IN = 'auth/SIGN_IN';
export const SIGN_OUT = 'auth/SIGN_OUT';

export const initState = {
  isAuthenticated: false,
  userName: '',
  userId: '',
  role: '',
  token: '',
  company: '',
  permissions: [],
};

// Reducer
export default (
  state = initState,
  { type, userName, userId, role, token, permissions, company } = {}
) => {
  switch (type) {
    case SIGN_IN:
      return {
        isAuthenticated: true,
        userName,
        userId,
        role,
        token,
        permissions,
        company,
      };

    case SIGN_OUT:
      return initState;

    default:
      return state;
  }
};

/**
 * Action Creators
 *
 * Success
 *   1. dispatch();
 *   2. goToNextPage();
 * Fail
 *   1. error()
 *
 * @param {{userName: string, password: string, company: string}} user user input name
 * @param {Function} goToNextPage callback function
 * @param {Function} error when wrong call this
 */
export const signInActionCreator = (user, goToNextPage, error) => (dispatch) =>
  signIn(user)
    .then(({ userId, userName, role, token, permissions, company }) => {
      dispatch({
        type: SIGN_IN,
        token,
        userName,
        userId,
        role,
        company,
        permissions,
      });
      goToNextPage();
    })
    .catch(error);
