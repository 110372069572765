import { Button, Container, Grid, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';

import GoToTop from 'components/GoToTop';
import { postProject } from '../../api/projects';
import { fetchProjects } from '../../ducks/projects';
import useSnackbar from '../../hooks/useSnackbar';
import ConnectedProject from './Project';

/**
 * onclick => post to database => redux fetch data => refresh projects page
 */
export function Projects({ projects, fetchProjects: getProjects }) {
  const useOvertimeFeature = useSelector(
    ({ setting }) => setting.useOvertimeFeature
  );
  const [projectId, setProjectId] = useState('');
  const [projectName, setProjectName] = useState('');

  const { successSnackbar, errorSnackbar } = useSnackbar();

  /**
   * fetch projects
   * 由於 useEffect 回傳值不能為 Promise
   * 因此用大括號包住
   */
  useEffect(() => {
    getProjects();
  }, [getProjects]);

  const onSubmit = async (e) => {
    /**
     * if use form should put e.preventDefault()
     * or may cause page be reload and make promise be canceled
     */
    e.preventDefault();

    /**
     * 防止相同的 ID
     */
    if (projects.some((v) => v.projectId === projectId)) {
      errorSnackbar('Error: 已經有了相同的 Project ID');
      return;
    }

    if (/-/.test(projectId)) {
      errorSnackbar('格式錯誤: Project ID 不可以有 -');
      return;
    }

    try {
      await postProject({ projectId, projectName });
      successSnackbar();
      getProjects();
      setProjectId('');
      setProjectName('');
    } catch (error) {
      errorSnackbar(error);
    }
  };

  return (
    <Container>
      <Grid container spacing={1}>
        <Grid item xs={useOvertimeFeature ? 2 : 1} />
        <Grid item xs={2}>
          <TextField
            label="Project ID"
            value={projectId}
            onChange={(e) => setProjectId(e.target.value)}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={useOvertimeFeature ? 6 : 7}>
          <TextField
            label="Project Name"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={1}>
          <div />
        </Grid>
        <Grid item xs={1}>
          <Button
            type="submit"
            onClick={onSubmit}
            color="primary"
            variant="contained"
          >
            Upload
          </Button>
        </Grid>
        <Grid item xs={12}>
          <br />
        </Grid>
        {projects.map((v) => (
          <ConnectedProject
            key={v.projectId}
            projectId={v.projectId}
            projectName={v.projectName}
            projectLimit={v.projectLimit}
            projectActive={v.projectActive}
            projectOvertimeActive={v.projectOvertimeActive}
          />
        ))}
      </Grid>
      <GoToTop />
    </Container>
  );
}

Projects.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      type: PropTypes.string,
    })
  ).isRequired,
  fetchProjects: PropTypes.func.isRequired,
};

const mapState = ({ projects }) => ({ projects });

export default connect(mapState, { fetchProjects })(Projects);
