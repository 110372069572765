import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { getTotalOvertimeHour, postClocks } from 'api/clocks';
import { putPreTimesheets } from 'api/preTimesheets';
import { getTimesheets, putTimesheets } from 'api/timesheets';
import PreFillTimesheets from 'components/PreFillTimesheets';
import {
  endOfMonth,
  endOfToday,
  isWeekend,
  startOfMonth,
  startOfToday,
} from 'date-fns';
import { appendOverTimes, initTimesheet } from 'helpers/timesheet';
import useSnackbar from 'hooks/useSnackbar';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import AlertDialog from './AlertDialog';

function StartOverTimeButton({ userId, clocks, setClocks }) {
  const [open, setOpen] = useState(false);
  const [openWeekendAlert, setOpenWeekendAlert] = useState(false);
  const [startOverTimeClock, setStartOverTimeClock] = useState(null);
  const [timesheets, setTimesheets] = useState([
    initTimesheet({ overTime: 0.5 }),
  ]);

  const { errorSnackbar, warningSnackbar } = useSnackbar();

  const showPreFillTimesheets = async () => {
    const startDate = startOfToday();

    try {
      const totalOvertimeHour = await getTotalOvertimeHour({
        userId,
        startDate: startOfMonth(startDate),
        endDate: endOfMonth(startDate),
      });

      if (totalOvertimeHour > 56) {
        errorSnackbar(
          `目前總加班 ${totalOvertimeHour} 小時，超過上限 56 小時，加班、加班結束請手寫打卡紀錄單。`,
          { persist: true }
        );
        return;
      }
      if (totalOvertimeHour >= 46) {
        warningSnackbar(
          `目前總加班 ${totalOvertimeHour} 小時，超過 46 小時，上限 56 小時。`,
          { persist: true }
        );
      }
    } catch (error) {
      errorSnackbar(error, { persist: true });
    }

    setOpen(true);
    setStartOverTimeClock(new Date());
  };

  const onClickWeekendAlertAction = async () => {
    await showPreFillTimesheets();
    setOpenWeekendAlert(false);
  };

  const onClickButton = async () => {
    if (isWeekend(new Date())) {
      setOpenWeekendAlert(true);
    } else {
      await showPreFillTimesheets();
    }
  };

  const onClickAction = async () => {
    const startDate = startOfToday();
    const endDate = endOfToday();
    try {
      const prevTimesheets = await getTimesheets({
        userId,
        startDate,
        endDate,
      });

      const params = {
        startDate,
        endDate,
        timesheets: appendOverTimes([...prevTimesheets, ...timesheets]).map(
          (value) => ({
            date: startDate.toISOString(),
            userId,
            ...value,
          })
        ),
      };

      await Promise.all([putTimesheets(params), putPreTimesheets(params)]);

      const payload = await postClocks({
        type: '加班',
        userId,
        date: startOverTimeClock,
      });

      setOpen(false);
      setClocks([...payload, ...clocks]);
    } catch (error) {
      errorSnackbar(error);
    }
  };

  return (
    <>
      <Button
        onClick={onClickButton}
        variant="outlined"
        color="primary"
        fullWidth
      >
        加班
      </Button>
      <Dialog
        open={openWeekendAlert}
        onClose={() => setOpenWeekendAlert(false)}
        maxWidth="md"
      >
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            有關假日加班
          </Typography>
          <Typography variant="h6">1. 請妥善填寫加班資料</Typography>
          <Typography variant="h6">
            2. 依據相關法令，每段時間避免超過 4 小時，要有休息
          </Typography>
          <Typography variant="h6" gutterBottom>
            3. 總時間建議不超過 6 小時
          </Typography>
          <Typography variant="h6" gutterBottom>
            如有用餐或非生產性活動，應下加班，待正式加班後方開始計為加班
          </Typography>
          <Typography variant="h6">感謝善用加班資源</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClickWeekendAlertAction}>
            確認
          </Button>
        </DialogActions>
      </Dialog>

      <AlertDialog
        open={open}
        setOpen={setOpen}
        onClickAction={onClickAction}
        actionName="加班"
      >
        <PreFillTimesheets
          timesheets={timesheets}
          userId={userId}
          setTimesheets={setTimesheets}
        />
      </AlertDialog>
    </>
  );
}

StartOverTimeButton.propTypes = {
  userId: PropTypes.string.isRequired,
  clocks: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
  setClocks: PropTypes.func.isRequired,
};

export default StartOverTimeButton;
