import PropTypes from 'prop-types';
import React, { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Header from '../components/Header';
import PrivateRoute from '../components/PrivateRoute';
import Attendance from './Attendance';
import Clock from './Clock';
import ConnectedDailyPlan from './DailyPlan';
import Metrics from './Metrics';
import ConnectedProjects from './Projects';
import RemoteAttendance from './RemoteAttendance/RemoteAttendance';
import RemoteClock from './RemoteClock/RemoteClock';
import Settings from './Settings';
import ConnectedSignIn from './SignIn';
import SignInCompany from './SignInCompany';
import ConnectedTimesheets from './Timesheets';
import ConnectedUsers from './Users';

const ConnectedReports = lazy(() => import('./Reports'));

/**
 * 由於需要認證有沒有登入 因此多拆一個 Component 來 connect redux
 * 這個 component 的目的為管理路由
 */
export function App({ isAuthenticated }) {
  return (
    <Router>
      {isAuthenticated && <Header />}
      <Suspense
        fallback={
          <div
            style={{
              'max-width': '1280px',
              'padding-left': '24px',
              'padding-right': '24px',
              'margin-left': '42.5px',
              'margin-right': '42.5px',
            }}
          >
            Loading...
          </div>
        }
      >
        <Switch>
          <Route exact path="/" component={ConnectedSignIn} />
          <Route path="/signin-company" component={SignInCompany} />
          <PrivateRoute
            path="/clock"
            component={Clock}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/remote-clock"
            component={RemoteClock}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/timesheets"
            component={ConnectedTimesheets}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/projects"
            component={ConnectedProjects}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/reports"
            component={ConnectedReports}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/daily-plan"
            component={ConnectedDailyPlan}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/users"
            component={ConnectedUsers}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/metrics"
            component={Metrics}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/attendance"
            component={Attendance}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/remote-attendance"
            component={RemoteAttendance}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/settings"
            component={Settings}
            isAuthenticated={isAuthenticated}
          />
        </Switch>
      </Suspense>
    </Router>
  );
}

App.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

function mapState({ auth: { isAuthenticated } }) {
  return { isAuthenticated };
}

export default connect(mapState)(App);
