import { isEmpty } from 'lodash';
import { getISODate } from './helpers/date';
import { fetcher } from './helpers/fetch';

export const deleteClock = ({ _id, bearerToken }) =>
  fetcher(`api/clocks/${_id}`, {
    method: 'DELETE',
    headers: { Authorization: bearerToken },
  });

/**
 *
 * @param {Object} param
 * @param {Date} param.startDate
 * @param {Date} param.endDate
 * @param {?string} param.userId
 * @param {[string]=} param.types
 * @param {?string} param.sort
 * @param {string=} param.bearerToken
 * @return {Promise}
 */
export const getClocks = ({
  startDate,
  endDate,
  types,
  userId = null,
  sort = null,
  bearerToken,
}) => {
  const startISODate = getISODate(startDate);
  const endISODate = getISODate(endDate);

  let query = `startDate=${startISODate}&endDate=${endISODate}`;

  if (!isEmpty(types)) {
    query += `&type=${types.join('&type=')}`;
  }

  if (userId !== null) {
    query += `&userId=${userId}`;
  }

  if (sort !== null) {
    query += `&sort=${sort}`;
  }

  return fetcher(`api/clocks?${query}`, {
    headers: { Authorization: bearerToken },
  });
};

export const getTotalOvertimeHour = ({
  startDate,
  endDate,
  userId,
  bearerToken,
}) => {
  const startISODate = getISODate(startDate);
  const endISODate = getISODate(endDate);

  const query = `startDate=${startISODate}&endDate=${endISODate}&userId=${userId}`;

  return fetcher(`api/clocks/total-overtime-hour?${query}`, {
    headers: { Authorization: bearerToken },
  });
};

/**
 * 新增一筆打卡記錄
 * @param {Object} param
 * @param {string} param.type
 * @param {string} param.userId
 * @param {Date | string} [param.date]
 * @param {Boolean} [param.remote]
 * @param {string=} param.bearerToken
 * @return {Promise}
 */
export const postClocks = ({
  type,
  userId,
  date,
  remote = false,
  bearerToken,
} = {}) => {
  const ISODate = getISODate(date);

  return fetcher('api/clocks', {
    method: 'POST',
    body: JSON.stringify([{ date: ISODate, type, userId, remote }]),
    headers: {
      'Content-Type': 'application/json',
      Authorization: bearerToken,
    },
  });
};

/**
 * 新增多筆打卡記錄
 * TODO: 增加 remote 參數
 * @param {Object} param
 * @param {Object} param.clocks
 * @param {string} param.clocks.type
 * @param {string} param.clocks.userId
 * @param {Date | string} [param.clocks.date]
 * @param {string=} param.bearerToken
 * @return {Promise}
 */
export const postMultiClocks = ({ clocks, bearerToken }) => {
  const body = clocks.map((clock) => {
    const ISODate = getISODate(clock.date);
    return { date: ISODate, type: clock.type, userId: clock.userId };
  });

  return fetcher('api/clocks', {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Authorization: bearerToken,
    },
  });
};
