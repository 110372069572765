import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { setAuthToken } from '../api/helpers/authToken';
import { SIGN_IN, SIGN_OUT } from './account';
import reducers from './reducers';

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true })
    : compose;

const saveAuthToken = () => (next) => (action) => {
  if (action.type === SIGN_IN) {
    // after a successful login, update the token in the API
    setAuthToken(action.token);
  } else if (action.type === SIGN_OUT) {
    setAuthToken(null);
  }

  // continue processing this action
  return next(action);
};

// Be sure to ONLY add this middleware in development!
const middleware =
  process.env.NODE_ENV !== 'production'
    ? [
        // eslint-disable-next-line global-require, import/no-extraneous-dependencies
        require('redux-immutable-state-invariant').default(),
        thunk,
        saveAuthToken,
      ]
    : [thunk, saveAuthToken];

// Create a Redux store holding the state of your app.
// Its API is { subscribe, dispatch, getState }.
const configureStore = ({ preloadedState = {} } = {}) =>
  createStore(
    reducers,
    preloadedState,
    composeEnhancers(applyMiddleware(...middleware))
  );

export default configureStore;
