import { Grid, IconButton, TextField, Tooltip } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import ProjectAutocomplete from '../ProjectAutocomplete';
import TaskAutocomplete from '../TaskAutocomplete';
import { getProjectsHash } from './projectSelectors';
import { ADD_ROW_AFTER_INDEX, DELETE_INDEX_ROW, PUT } from './reducer';

/**
 * 每個 list 接收該 id 和 data
 */
export function TimesheetRow({ index, rowData, send, disabled }) {
  const projectsHash = useSelector(getProjectsHash);

  const tasks = useSelector(({ setting }) => setting.tasks);
  const useOvertimeFeature = useSelector(
    ({ setting }) => setting.useOvertimeFeature
  );

  const onClickAdd = () => {
    send({
      type: ADD_ROW_AFTER_INDEX,
      payload: { index },
    });
  };

  const onClickRemove = () => {
    send({
      type: DELETE_INDEX_ROW,
      payload: { index },
    });
  };

  const onChange = (e) => {
    const { name, type, value } = e.target;
    return type === 'text'
      ? send({ type: PUT, payload: { index, name, value } })
      : /**
         * https://stackoverflow.com/questions/469357/html-text-input-allow-only-numeric-input
         * 不同瀏覽器的實現不太一樣，避免輸入 e 無法 handle，所以多個 0
         * parseFloat(value) || 0
         */
        send({
          type: PUT,
          payload: { index, name, value: parseFloat(value) || 0 },
        });
  };

  const setProjectId = (projectId) => {
    send({
      type: PUT,
      payload: { index, name: 'projectId', value: projectId },
    });
  };

  const setTask = (task) => {
    send({
      type: PUT,
      payload: { index, name: 'task', value: task },
    });
  };

  return (
    <>
      {!disabled && (
        <Grid item sm={1} xs={12} container justifyContent="center">
          <Tooltip title="Add">
            <span>
              <IconButton size="small" onClick={onClickAdd}>
                <Add />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Remove">
            <span>
              <IconButton size="small" onClick={onClickRemove}>
                <Remove />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      )}
      <Grid item sm={disabled ? 4 : 3} xs={12}>
        <ProjectAutocomplete
          projectId={rowData.projectId}
          setProjectId={setProjectId}
        />
      </Grid>
      <Grid item sm={2} xs={12}>
        {tasks.length === 0 ? (
          <TextField
            name="task"
            value={rowData.task}
            onChange={onChange}
            size="small"
            fullWidth
          />
        ) : (
          <TaskAutocomplete
            task={rowData.task ? rowData.task : null}
            setTask={setTask}
          />
        )}
      </Grid>
      <Grid item sm={useOvertimeFeature ? 4 : 5} xs={12}>
        <TextField
          name="remark"
          value={rowData.remark}
          onChange={onChange}
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item sm={1} xs={6}>
        <TextField
          name="normalHour"
          type="number"
          value={rowData.normalHour}
          onChange={onChange}
          inputProps={{ style: { textAlign: 'center' } }}
          size="small"
          fullWidth
        />
      </Grid>
      {useOvertimeFeature && (
        <Grid item sm={1} xs={6}>
          <TextField
            name="overTime"
            type="number"
            value={rowData.overTime}
            onChange={onChange}
            inputProps={{ style: { textAlign: 'center' } }}
            size="small"
            fullWidth
            disabled={!projectsHash[rowData.projectId]?.projectOvertimeActive}
          />
        </Grid>
      )}
    </>
  );
}

TimesheetRow.propTypes = {
  index: PropTypes.number.isRequired,
  rowData: PropTypes.shape({
    projectId: PropTypes.string,
    task: PropTypes.string,
    remark: PropTypes.string,
    normalHour: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    overTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  send: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

TimesheetRow.defaultProps = {
  disabled: false,
};

export default TimesheetRow;
