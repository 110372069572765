import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

function DeleteDialog({ open, setOpen, onDelete, content }) {
  const onClose = () => setOpen(false);

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="delete comfirm">
      <DialogTitle id="alert-dialog-title">Delete Comfirm</DialogTitle>
      <DialogContent id="alert-dialog-title">{content}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus>
          Cancel
        </Button>
        <Button
          onClick={() => {
            onClose();
            onDelete();
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
};

export default DeleteDialog;
