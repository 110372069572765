import DateFnsUtils from '@date-io/date-fns';
import { CssBaseline, IconButton } from '@material-ui/core';
import {
  createTheme,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ConnectedApp from './App';

/**
 * https://in-your-saas.github.io/material-ui-theme-editor/
 */
const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, Noto Sans TC, sans-serif',
  },
  palette: {
    // #f8f9fa
    primary: {
      light: '#484848',
      main: '#212121',
      dark: '#000000',
    },
    // #0b7bf1
    secondary: {
      light: '#4dabf5',
      main: '#2196f3',
      dark: '#1769aa',
    },
  },
});

function Theme() {
  const notistackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <CssBaseline>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <StylesProvider injectFirst>
            <SnackbarProvider
              preventDuplicate
              maxSnack={7}
              ref={notistackRef}
              autoHideDuration={3000}
              // eslint-disable-next-line react/no-unstable-nested-components
              action={(key) => (
                <IconButton
                  size="small"
                  color="inherit"
                  onClick={onClickDismiss(key)}
                >
                  <Close fontSize="small" />
                </IconButton>
              )}
            >
              <ConnectedApp />
            </SnackbarProvider>
          </StylesProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </CssBaseline>
  );
}

export default Theme;
