import { endOfDay, isWithinInterval, startOfDay } from 'date-fns';
import { keyBy, memoize, round, uniqueId } from 'lodash';

/**
 * @typedef {Object} Timesheet
 * @property {String=} _id
 * @property {!Date} date
 * @property {!String} userId
 * @property {!String} projectId
 * @property {String=} task
 * @property {String=} remark
 * @property {!Number} normalHour
 * @property {!Number} overTime
 * @property {!Number} overTime1
 * @property {!Number} overTime2
 */

/**
 * filter data by startDate and endDate
 * 從 startDate 00.00 到 endDate 23.99
 *
 * @param {Array<{date: Date}>} data array of object
 * @param {Date} startDate
 * @param {Date} endDate
 */
export const filterByDate = memoize(
  (data, startDate, endDate) => {
    const start = startOfDay(startDate);
    const end = endOfDay(endDate);
    return data.filter((v) =>
      isWithinInterval(new Date(v.date), { start, end })
    );
  },
  (...args) => JSON.stringify(args)
);

/**
 * 多種不同時數
 * @typedef {{normalHour: Number, overTime: Number, overTime1: Number, overTime2: Number}} Hour
 * @typedef {{normalHour: Number, overTime: Number, overTime1: Number, overTime2: Number, totalHour:Number, costHour: Number}} CalculatedHour
 */

/**
 * reduce hours
 * 將該種 type 全部時間加起來
 *
 * @param {Array<Hour>} hours array of object
 * @param {String} type
 * @returns {Number}
 */
export const reduceHourByType = (hours, type) =>
  hours.map((v) => v[type]).reduce((acc, cur) => acc + cur, 0);

/**
 * reduce hours => { normalHour, overTime, overTime1, overTime2, costHour }
 *
 * @param {Array<Hour>} hours array of object
 * @returns {CalculatedHour}
 */
export const reduceHours = (hours) => {
  const normalHour = reduceHourByType(hours, 'normalHour');
  const overTime = reduceHourByType(hours, 'overTime');
  const overTime1 = reduceHourByType(hours, 'overTime1');
  const overTime2 = reduceHourByType(hours, 'overTime2');

  /**
   * https://stackoverflow.com/questions/11832914/round-to-at-most-2-decimal-places-only-if-necessary
   * to ensure things like 1.005 round correctly
   * const round = (number) => Math.round((number + Number.EPSILON) * 100) / 100;
   */
  const totalHour = round(normalHour + overTime1 + overTime2, 2);
  const costHour = round(normalHour + 1.34 * overTime1 + 1.67 * overTime2, 2);

  return {
    normalHour,
    overTime,
    overTime1,
    overTime2,
    totalHour,
    costHour,
  };
};

/**
 * 計算工時比例
 *
 * @param {CalculatedHour} hours
 * @returns {{normalHour: Number, overTime1: Number, overTime2: Number}}
 */
export const getRatioHours = (hours) => {
  const { overTime1, overTime2, totalHour } = hours;

  const ratio = {
    overTime1: round((overTime1 / totalHour) * 100),
    overTime2: round((overTime2 / totalHour) * 100),
  };

  ratio.normalHour = 100 - ratio.overTime1 - ratio.overTime2;

  return ratio;
};

/**
 * 計算 projects 所消耗的時數
 *
 * @param {Array<Timesheet>} timesheets
 * @param {Array<{projectId: String, projectName: String}>} projects
 * @returns {Array<CalculatedHour>}
 */
export const reduceProjectHours = (timesheets, projects) => {
  const hashProjects = keyBy(projects, 'projectId');

  /**
   * A value in the Set may only occur once; it is unique in the Set's collection.
   */
  return Array.from(new Set(timesheets.map((v) => v.projectId)))
    .map((projectId) => {
      const sameProjectIdTimesheets = timesheets.filter(
        (v) => v.projectId === projectId
      );

      const { projectName } = hashProjects[projectId] || {
        projectName: '錯誤：沒有 Project 名稱！！！',
      };

      const hours = reduceHours(sameProjectIdTimesheets);

      return {
        project: `${projectId} - ${projectName}`,
        ...hours,
      };
    })
    .sort((a, b) => b.costHour - a.costHour);
};

/**
 * 計算 users 所消耗的時數
 *
 * @param {Array<Timesheet>} timesheets
 * @param {Array<{userId: String, userName: String}>} users
 * @returns {Array<CalculatedHour>}
 */
export const reduceUserHours = (timesheets, users) => {
  const hashUsers = keyBy(users, 'userId');

  /**
   * A value in the Set may only occur once; it is unique in the Set's collection.
   */
  return Array.from(new Set(timesheets.map((v) => v.userId)))
    .map((userId) => {
      const sameProjectIdTimesheets = timesheets.filter(
        (v) => v.userId === userId
      );

      const { userName } = hashUsers[userId] || {
        userName: '錯誤：沒有 User 名稱！！！',
      };

      const hours = reduceHours(sameProjectIdTimesheets);

      return {
        user: userName,
        ...hours,
      };
    })
    .sort((a, b) => b.costHour - a.costHour);
};

/**
 * return: timesheet object
 *
 * https://material-ui.com/api/autocomplete/
 * if projectId is '' Autocomplete autoHighlight will be invalid
 * so set projectId = null
 */
export const initTimesheet = ({ overTime = 0 } = {}) => ({
  _id: uniqueId('key_'),
  projectId: null,
  task: '',
  remark: '',
  normalHour: 0,
  overTime,
});

export const appendOverTimes = (timesheets) => {
  let totalOverTime1 = 0;

  return timesheets.map(
    ({ projectId, task = '', remark = '', normalHour = 0, overTime = 0 }) => {
      let overTime1 = 0;
      let overTime2 = 0;

      if (overTime + totalOverTime1 <= 2) {
        overTime1 = overTime;
        overTime2 = 0;
        totalOverTime1 += overTime;
      } else {
        overTime1 = 2 - totalOverTime1;
        overTime2 = overTime - overTime1;
        totalOverTime1 = 2;
      }

      return {
        projectId,
        task,
        remark,
        normalHour,
        overTime,
        overTime1,
        overTime2,
      };
    }
  );
};
