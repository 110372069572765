import { Button } from '@material-ui/core';
import {
  endOfMonth,
  endOfToday,
  isAfter,
  startOfMonth,
  startOfToday,
} from 'date-fns';
import useSnackbar from 'hooks/useSnackbar';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { initTimesheet } from 'helpers/timesheet';
import { getTotalOvertimeHour, postClocks } from '../../api/clocks';
import { getTimesheets } from '../../api/timesheets';
import ConnectedTimesheet from '../../components/Timesheet';
import {
  calNormalHourByClocks,
  calOverTimeHourByClocks,
} from '../../helpers/clock';
import { reduceHourByType } from '../../helpers/timesheet';
import AlertDialog from './AlertDialog';
import MoodRating from './MoodRating';

function EndOverTimeButton({ userId, clocks, setClocks }) {
  const [open, setOpen] = useState(false);
  const [timesheets, setTimesheets] = useState([]);
  const lunchBreak = useSelector(({ setting }) => setting.lunchBreak);

  const { warningSnackbar, infoSnackbar, errorSnackbar } = useSnackbar();

  /**
   * 1. 檢查打卡
   *      有問題，不檢查，可打卡
   *      沒有問題，get timesheets
   *        如果一致，給打卡
   *        不一致，open dialog
   */
  const onClickOverTimeEnd = async () => {
    const nineClock = new Date();
    nineClock.setHours(21, 0, 0, 0);

    if (isAfter(new Date(), nineClock)) {
      infoSnackbar(`9:00 以後如需加班, 請另填加班單, 謝謝配合`);
      return;
    }

    try {
      const totalOvertimeHour = await getTotalOvertimeHour({
        userId,
        startDate: startOfMonth(startOfToday()),
        endDate: endOfMonth(startOfToday()),
      });

      if (totalOvertimeHour > 56) {
        errorSnackbar(
          `目前總加班 ${totalOvertimeHour} 小時，超過上限 56 小時，加班、加班結束請手寫打卡紀錄單。`,
          { persist: true }
        );
        return;
      }
      if (totalOvertimeHour >= 46) {
        warningSnackbar(
          `目前總加班 ${totalOvertimeHour} 小時，超過 46 小時，上限 56 小時。`,
          { persist: true }
        );
      }
    } catch (error) {
      errorSnackbar(error, { persist: true });
    }

    const oneDayTimesheets = await getTimesheets({
      userId,
      startDate: startOfToday(),
      endDate: endOfToday(),
    });

    setTimesheets(
      isEmpty(oneDayTimesheets) ? [initTimesheet()] : oneDayTimesheets
    );
    setOpen(true);
  };

  /**
   * 1. 計算打卡時間
   * 2. get timesheets
   * 3. 比較時間
   *      不一致，show 提示
   *      一致，給打卡
   */
  const onClickAction = async () => {
    const normalHour = calNormalHourByClocks(clocks, lunchBreak);
    const overtimeHour = calOverTimeHourByClocks([
      { type: '加班結束', date: new Date() },
      ...clocks,
    ]);

    const oneDayTimesheets = await getTimesheets({
      userId,
      startDate: startOfToday(),
      endDate: endOfToday(),
    });

    const timesheetOvertimeHour = reduceHourByType(
      oneDayTimesheets,
      'overTime'
    );
    const timesheetNormalHour = reduceHourByType(
      oneDayTimesheets,
      'normalHour'
    );

    const isClockValid = normalHour >= 0 && overtimeHour >= 0;
    const isNotConsistent =
      Math.abs(overtimeHour - timesheetOvertimeHour) > 0.9 ||
      Math.abs(normalHour - timesheetNormalHour) > 0.9;

    // 比較相差時間
    if (isClockValid && isNotConsistent) {
      infoSnackbar(`請確認有無按 Timesheet 的上傳按鈕或是檢查時數是否一致`);
      return;
    }

    const payload = await postClocks({ type: '加班結束', userId });

    setOpen(false);
    setClocks([...payload, ...clocks]);
  };

  return (
    <>
      <Button
        onClick={onClickOverTimeEnd}
        variant="outlined"
        color="primary"
        fullWidth
      >
        加班結束
      </Button>
      <AlertDialog
        open={open}
        setOpen={setOpen}
        onClickAction={onClickAction}
        actionName="加班結束"
      >
        <MoodRating userId={userId} date={startOfToday()} />
        <ConnectedTimesheet
          date={startOfToday()}
          userId={userId}
          timesheets={timesheets}
          clocks={[{ type: '加班結束', date: new Date() }, ...clocks]}
        />
      </AlertDialog>
    </>
  );
}

EndOverTimeButton.propTypes = {
  userId: PropTypes.string.isRequired,
  clocks: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
  setClocks: PropTypes.func.isRequired,
};

export default EndOverTimeButton;
