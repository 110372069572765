import { Grid, IconButton, Radio, TextField, Tooltip } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import { initTimesheet } from 'helpers/timesheet';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ProjectAutocomplete from '../ProjectAutocomplete';

function PreFillTimesheet({ index, setTimesheets, timesheet }) {
  const [selectedValue, setSelectedValue] = useState(0.5);

  const onClickAdd = () => {
    setTimesheets((timesheets) => {
      const newTimesheets = [...timesheets];
      newTimesheets.splice(index + 1, 0, initTimesheet({ overTime: 0.5 }));
      return newTimesheets;
    });
  };

  const onClickRemove = () => {
    setTimesheets((timesheets) => {
      // 如果長度只剩下 1 不給修改
      if (timesheets.length === 1) {
        return timesheets;
      }
      const newTimesheets = [...timesheets];
      newTimesheets.splice(index, 1);
      return newTimesheets;
    });
  };

  const onChangeRadio = (event) => {
    const overTime = parseFloat(event.target.value);
    setSelectedValue(overTime);
    setTimesheets((timesheets) => {
      const newTimesheets = [...timesheets];
      newTimesheets[index].overTime = overTime;
      return newTimesheets;
    });
  };

  const onChangeTask = (event) => {
    const { value } = event.target;
    setTimesheets((timesheets) => {
      const newTimesheets = [...timesheets];
      newTimesheets[index].task = value;
      return newTimesheets;
    });
  };

  const onChangeRemark = (event) => {
    const { value } = event.target;
    setTimesheets((timesheets) => {
      const newTimesheets = [...timesheets];
      newTimesheets[index].remark = value;
      return newTimesheets;
    });
  };

  const setProjectId = (projectId) => {
    setTimesheets((timesheets) => {
      const newTimesheets = [...timesheets];
      newTimesheets[index].projectId = projectId;
      return newTimesheets;
    });
  };

  return (
    <>
      <Grid item xs={1}>
        <Tooltip title="Add">
          <span>
            <IconButton size="small" onClick={onClickAdd}>
              <Add />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Remove">
          <span>
            <IconButton size="small" onClick={onClickRemove}>
              <Remove />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
      <Grid item xs={3}>
        <ProjectAutocomplete
          projectId={timesheet.projectId}
          setProjectId={setProjectId}
          filterProjectOvertimeActive
        />
      </Grid>
      <Grid item xs={1}>
        <TextField name="task" size="small" fullWidth onChange={onChangeTask} />
      </Grid>
      <Grid item xs={2}>
        <TextField
          name="remark"
          size="small"
          fullWidth
          onChange={onChangeRemark}
        />
      </Grid>
      <Grid item xs={1} container justifyContent="center">
        <Radio
          checked={selectedValue === 0.5}
          onChange={onChangeRadio}
          value={0.5}
          name="overTime-0.5"
          inputProps={{ 'aria-label': 0.5 }}
          size="small"
        />
      </Grid>
      <Grid item xs={1} container justifyContent="center">
        <Radio
          checked={selectedValue === 1.0}
          onChange={onChangeRadio}
          value={1.0}
          name="overTime-1.0"
          inputProps={{ 'aria-label': 1.0 }}
          size="small"
        />
      </Grid>
      <Grid item xs={1} container justifyContent="center">
        <Radio
          checked={selectedValue === 1.5}
          onChange={onChangeRadio}
          value={1.5}
          name="overTime-1.5"
          inputProps={{ 'aria-label': 1.5 }}
          size="small"
        />
      </Grid>
      <Grid item xs={1} container justifyContent="center">
        <Radio
          checked={selectedValue === 2.0}
          onChange={onChangeRadio}
          value={2.0}
          name="overTime-2.0"
          inputProps={{ 'aria-label': 2.0 }}
          size="small"
        />
      </Grid>
      <Grid item xs={1} container justifyContent="center">
        <Radio
          checked={selectedValue === 3.0}
          onChange={onChangeRadio}
          value={3.0}
          name="overTime-3.0"
          inputProps={{ 'aria-label': 3.0 }}
          size="small"
        />
      </Grid>
    </>
  );
}

PreFillTimesheet.propTypes = {
  index: PropTypes.number.isRequired,
  setTimesheets: PropTypes.func.isRequired,
  timesheet: PropTypes.shape({
    _id: PropTypes.string,
    projectId: PropTypes.string,
    task: PropTypes.string,
    remark: PropTypes.string,
    normalHour: PropTypes.number,
    overTime: PropTypes.number,
  }).isRequired,
};

export default PreFillTimesheet;
