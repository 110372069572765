import {
  colors,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  SentimentVeryDissatisfied,
  SentimentVerySatisfied,
} from '@material-ui/icons';
import { EmoticonNeutralOutline } from 'mdi-material-ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { getMoods, putMood } from '../../api/moods';
import { getPrivateImage } from '../../api/privateImage';
import { noLogErrorMessage } from '../../helpers/fetch';

const shuffleArray = (array) => array.sort(() => Math.random() - 0.5);

function MoodRating({ userId, date }) {
  const [value, setValue] = useState();
  const [dissatisfiedMoodImg, setDissatisfiedMoodImg] = useState('');
  const [neutralMoodImg, setNeutralMoodImg] = useState('');
  const [satisfiedMoodImg, setSatisfiedMoodImg] = useState('');
  const [orders, setOrders] = useState([0, 1, 2]);

  useEffect(() => {
    setOrders(shuffleArray(orders));

    getMoods({ userId, startDate: date, endDate: date }).then(
      (v) => v.length === 1 && setValue(v[0].value)
    );

    getPrivateImage({ filename: 'mood/-1' })
      .then((url) => setDissatisfiedMoodImg(url))
      .catch(noLogErrorMessage);
    getPrivateImage({ filename: 'mood/0' })
      .then((url) => setNeutralMoodImg(url))
      .catch(noLogErrorMessage);
    getPrivateImage({ filename: 'mood/1' })
      .then((url) => setSatisfiedMoodImg(url))
      .catch(noLogErrorMessage);
  }, [date, userId, orders]);

  const onClick = (v) => () => {
    putMood({ date, userId, value: v }).then(() => setValue(v));
  };

  const elements = [
    <Tooltip title="不滿意" key="不滿意">
      <span>
        <IconButton onClick={onClick(-1)}>
          {dissatisfiedMoodImg ? (
            <img
              src={dissatisfiedMoodImg}
              alt="logo"
              style={{
                borderBottom:
                  value === -1 ? `2px solid ${colors.blue[700]}` : '0px',
              }}
              onError={() => setDissatisfiedMoodImg('')}
            />
          ) : (
            <SentimentVeryDissatisfied
              fontSize="large"
              style={{
                color: value === -1 ? colors.blue[700] : colors.grey[500],
              }}
            />
          )}
        </IconButton>
      </span>
    </Tooltip>,
    <Tooltip title="尚可" key="尚可">
      <span>
        <IconButton onClick={onClick(0)}>
          {neutralMoodImg ? (
            <img
              src={neutralMoodImg}
              alt="logo"
              style={{
                borderBottom:
                  value === 0 ? `2px solid ${colors.blue[700]}` : '0px',
              }}
              onError={() => setNeutralMoodImg('')}
            />
          ) : (
            <EmoticonNeutralOutline
              fontSize="large"
              style={{
                color: value === 0 ? colors.blue[700] : colors.grey[500],
              }}
            />
          )}
        </IconButton>
      </span>
    </Tooltip>,
    <Tooltip title="滿意" key="滿意">
      <span>
        <IconButton onClick={onClick(1)}>
          {satisfiedMoodImg ? (
            <img
              src={satisfiedMoodImg}
              alt="logo"
              style={{
                borderBottom:
                  value === 1 ? `2px solid ${colors.blue[700]}` : '0px',
              }}
              onError={() => setSatisfiedMoodImg('')}
            />
          ) : (
            <SentimentVerySatisfied
              fontSize="large"
              style={{
                color: value === 1 ? colors.blue[700] : colors.grey[500],
              }}
            />
          )}
        </IconButton>
      </span>
    </Tooltip>,
  ];

  return (
    <>
      <Grid container item xs={12} justifyContent="center">
        <Typography>您滿意今天的工作內容嗎？</Typography>
      </Grid>
      <Grid container item xs={12} justifyContent="center">
        {orders.map((order) => elements[order])}
      </Grid>
    </>
  );
}

MoodRating.propTypes = {
  userId: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
};

export default MoodRating;
