import { Button, Container, Grid, Hidden, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import {
  eachDayOfInterval,
  endOfDay,
  isMonday,
  isToday,
  startOfDay,
  startOfWeek,
  sub,
  subBusinessDays,
  subWeeks,
} from 'date-fns';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getClocks } from '../../api/clocks';
import { getTimesheets } from '../../api/timesheets';
import ConnectedTimesheet from '../../components/Timesheet';
import { fetchProjects } from '../../ducks/projects';
import { fetchSetting } from '../../ducks/setting';
import { error } from '../../helpers/fetch';
import { filterByDate } from '../../helpers/timesheet';
import style from './index.module.css';
/**
 * 直接在這裡 fetch，並創建多個 timesheet，傳入該 timesheet 的日期。
 */
export function Timesheets({
  userId,
  useOvertimeFeature,
  fetchProjects: getProjects,
  fetchSetting: reduxFetchSetting,
}) {
  /**
   * 改成放在 component 中，因為若放在外面，避免整個 App 一直沒有重新整理，造成只執行一次，跨天可能會有錯。
   */
  const [days, setDays] = useState(
    eachDayOfInterval({
      start: subBusinessDays(new Date(), 2),
      end: new Date(),
    }).reverse()
  );

  const [timesheets, setTimesheets] = useState([]);
  const [clocks, setClocks] = useState([]);

  useEffect(() => {
    getProjects();
    reduxFetchSetting();
  }, [getProjects, reduxFetchSetting]);

  useEffect(() => {
    const startDate = startOfDay(days[days.length - 1]);
    const endDate = endOfDay(days[0]);

    getTimesheets({ startDate, endDate, userId })
      .then((payload) => setTimesheets(payload))
      .catch(error);

    getClocks({ startDate, endDate, userId })
      .then((payload) => {
        if (isEmpty(payload)) {
          setClocks([]);
          return;
        }

        const lastClock = payload[0];

        const isTodayClock = isToday(new Date(lastClock.date));

        if (isTodayClock && lastClock.type === '上班') {
          setClocks([
            { type: '下班', date: new Date().toISOString() },
            ...payload,
          ]);
        } else if (isTodayClock && lastClock.type === '加班') {
          setClocks([
            { type: '加班結束', date: new Date().toISOString() },
            ...payload,
          ]);
        } else {
          setClocks(payload);
        }
      })
      .catch(error);
  }, [userId, days]);

  const onClick = () => {
    const moreEndDate = sub(days[days.length - 1], { days: 1 });

    /**
     * 如果是星期一，往前一週
     * 如果不是星期一，抓到這一週的星期一
     */
    const startDate = isMonday(moreEndDate)
      ? subWeeks(moreEndDate, 1)
      : startOfWeek(moreEndDate, { weekStartsOn: 1 });

    setDays(eachDayOfInterval({ start: startDate, end: days[0] }).reverse());
  };

  return (
    <Container>
      <Grid container spacing={1}>
        <Hidden xsDown>
          <Grid item xs={4}>
            <Typography paragraph classes={style}>
              專案
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography paragraph classes={style}>
              項目
            </Typography>
          </Grid>
          <Grid item xs={useOvertimeFeature ? 4 : 5}>
            <Typography paragraph classes={style}>
              內容
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography paragraph classes={style}>
              工時
            </Typography>
          </Grid>
          {useOvertimeFeature && (
            <Grid item xs={1}>
              <Typography paragraph classes={style}>
                加班
              </Typography>
            </Grid>
          )}
        </Hidden>
        {days.map((date) => (
          <ConnectedTimesheet
            key={date}
            date={date}
            timesheets={filterByDate(timesheets, date, date)}
            clocks={filterByDate(clocks, date, date)}
            disabled={date < subBusinessDays(days[0], 2)}
          />
        ))}
        <Grid item xs={12}>
          <Button
            onClick={onClick}
            variant="outlined"
            style={{ marginTop: '2.0em', marginBottom: '2.0em' }}
            endIcon={<ExpandMore />}
            color="primary"
          >
            MORE
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

Timesheets.propTypes = {
  userId: PropTypes.string.isRequired,
  useOvertimeFeature: PropTypes.bool.isRequired,
  fetchProjects: PropTypes.func.isRequired,
  fetchSetting: PropTypes.func.isRequired,
};

const mapState = (state) => ({
  userId: state.auth.userId,
  useOvertimeFeature: state.setting.useOvertimeFeature,
});

export default connect(mapState, { fetchProjects, fetchSetting })(Timesheets);
