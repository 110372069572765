import { Button, Container, Grid } from '@material-ui/core';
import { PhotoCamera } from '@material-ui/icons';
import { startOfToday } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getDailyPlan, postDailyPlan } from '../../api/dailyPlan';
import { noLogErrorMessage } from '../../helpers/fetch';
import useSnackbar from '../../hooks/useSnackbar';
import style from './index.module.css';

function Plans() {
  const userId = useSelector((state) => state.auth.userId);
  const [imgURL, setImgURL] = useState('');
  const [refetch, setRefetch] = useState(false);
  const { savingSnackbar, savedSnackbar, errorSnackbar } = useSnackbar();

  useEffect(() => {
    getDailyPlan({ userId, date: startOfToday() })
      .then(setImgURL)
      .catch(noLogErrorMessage);
  }, [userId, refetch]);

  const onChange = (file) => {
    if (!file) return;

    savingSnackbar();

    postDailyPlan({ file, date: startOfToday() })
      .then(setRefetch)
      .then(savedSnackbar)
      .catch(errorSnackbar);
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/*
           * 消除醜醜的按鈕
           * https://kiranvj.com/blog/blog/file-upload-in-material-ui/
           * https://github.com/mui-org/material-ui/issues/9716
           */}
          <label htmlFor="raised-button-file">
            <input
              accept="image/*"
              type="file"
              id="raised-button-file"
              name="planning"
              style={{ display: 'none' }}
              onChange={(e) => onChange(e.target.files[0])}
            />
            <Button
              component="span"
              color="primary"
              variant="outlined"
              startIcon={<PhotoCamera />}
            >
              Upload Daily Plan
            </Button>
          </label>
        </Grid>
        <Grid item xs={12}>
          {/*
           * 替代圖片方法
           * react onError 設定 style.display = 'none'; 後
           * 上傳圖片後會 rerender，但僅會改變有變化的 dom 的部分，因此會有一直都有 style.display = 'none'; 的問題
           * https://stackoverflow.com/questions/3984287/how-to-show-alternate-image-if-source-image-is-not-found-onerror-working-in-ie
           * https://stackoverflow.com/questions/22051573/how-to-hide-image-broken-icon-using-only-css-html
           * https://stackoverflow.com/questions/22051573/how-to-hide-image-broken-icon-using-only-css-html
           * https://github.com/vercel/next.js/discussions/14772
           */}
          {imgURL && (
            <img
              src={imgURL}
              alt="Daily Plan"
              className={style.img}
              onError={() => setImgURL('')}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default Plans;
