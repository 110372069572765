import { auth } from './authToken';
import { BASE_URL } from './config';

/**
 * fetch api status
 */
const status = (res) => (res.ok ? Promise.resolve(res) : Promise.reject(res));

// use content-type to resolve content
const getContent = (res) => {
  const contentType = res?.headers?.get('content-type');

  if (contentType && contentType.indexOf('application/json') !== -1) {
    return res.json();
  }

  // 總共五總方式，目前處理兩種
  // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#body
  // arrayBuffer()
  // blob()
  // json()
  // text()
  // formData()
  return res?.text();
};

/**
 * fetch in node
 * 我想要做一個 robust 的 fetch
 * 如果他有傳入自己的 Authorization 就用他自己的，如果沒有就用 Bearer token
 * 如果我做一個假設，我們都是用 bearer token
 * 我想要把 json 也作進來
 * - https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch
 * - https://stackoverflow.com/questions/48433783/referenceerror-fetch-is-not-defined
 * - https://github.com/node-fetch/node-fetch/blob/master/docs/v2-LIMITS.md
 * @param {RequestInfo} url
 * @param {RequestInit=} init
 * @returns {Promise<Response>}
 */
export const fetcher = async (url, { headers = {}, ...options } = {}) => {
  const { Authorization, ...restHeaders } = headers;

  // Authorization 可以是完整的 'Bearer token' || token
  const token = Authorization?.split(' ')?.[1] ?? Authorization;

  const init = {
    ...options,
    headers: {
      Authorization: `Bearer ${token ?? auth.token}`,
      ...restHeaders,
    },
  };

  try {
    const res = await fetch(new URL(url, BASE_URL), init);

    await status(res);

    return getContent(res);
  } catch (err) {
    const errorContent = await getContent(err);

    // 取消 new Error，這是由於在轉換成字串的時候會加上 Error:
    // 這部分比較難以控制
    // 但是 new Error 會更清楚是一個錯誤訊息
    // 這裡是一個 trade off
    return Promise.reject(errorContent?.errors?.message ?? errorContent);
  }
};

export default {
  fetcher,
};
