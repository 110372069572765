import { getISODate } from './helpers/date';
import { fetcher } from './helpers/fetch';

/**
 *
 * @param {Object} param
 * @param {Date | string} [param.date] start of date
 * @param {number} param.value
 * @param {string=} param.bearerToken
 * @return {Promise}
 */
export const putMood = ({ date, value, bearerToken }) => {
  const ISODate = getISODate(date);

  return fetcher('api/moods', {
    method: 'PUT',
    body: JSON.stringify({ date: ISODate, value }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: bearerToken,
    },
  });
};

/**
 *
 * @param {Object} param
 * @param {Date | string} param.startDate
 * @param {Date | string} param.endDate
 * @param {string=} param.userId
 * @param {string=} param.sort
 * @param {string=} param.bearerToken
 * @return {Promise}
 */
export const getMoods = ({ startDate, endDate, userId, sort, bearerToken }) => {
  const startISODate = getISODate(startDate);
  const endISODate = getISODate(endDate);

  let query = `?startDate=${startISODate}&endDate=${endISODate}`;

  query += userId ? `&userId=${userId}` : '';
  query += sort ? `&sort=${sort}` : '';

  return fetcher(`api/moods${query}`, {
    headers: { Authorization: bearerToken },
  });
};
