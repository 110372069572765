import { combineReducers } from 'redux';
import auth from './account';
import projects, { projectsV2 } from './projects';
import setting from './setting';
import users from './users';

/**
 * https://redux.js.org/style-guide/style-guide#name-state-slices-based-on-the-stored-data
 * 符合 Name State Slices Based On the Stored Data
 */
export default combineReducers({
  auth,
  projects,
  projectsV2,
  users,
  setting,
});
