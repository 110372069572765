import {
  Button,
  Container,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete, Pagination } from '@material-ui/lab';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { endOfDay, format, startOfDay, sub } from 'date-fns';
import { memoize } from 'lodash';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Delete } from '@material-ui/icons';
import GoToTop from 'components/GoToTop';
import useSnackbar from 'hooks/useSnackbar';
import { deleteClock, getClocks, postClocks } from '../../api/clocks';
import { fetchProjects } from '../../ducks/projects';
import { fetchUsers } from '../../ducks/users';
import { error } from '../../helpers/fetch';
import style from './index.module.css';

const reduce = memoize((users) =>
  users.reduce((dict, v) => ({ ...dict, [v.userId]: v.userName }), {})
);

const clockTypes = [
  '上班',
  '下班',
  '遠端上班',
  '遠端下班',
  '加班',
  '加班結束',
  '公出',
  '公出返回',
];

const initClock = () => ({
  date: null,
  type: null,
  userId: null,
  user: null,
});

function Attendance() {
  const [page, setPage] = useState(1);
  const [clocks, setClocks] = useState([]);
  const [clock, setClock] = useState(initClock);

  const users = useSelector((state) => state.users);
  const usersMap = reduce(users);
  const { errorSnackbar, successSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const fetchClocks = useCallback(() => {
    const date = sub(new Date(), { days: page - 1 });

    const startDate = startOfDay(date);
    const endDate = endOfDay(date);

    getClocks({ startDate, endDate })
      .then((payload) => setClocks(payload))
      .catch(error);
  }, [page]);

  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchProjects());
    fetchClocks();
  }, [dispatch, fetchClocks]);

  const onChange = (event, value) => {
    setPage(value);
  };

  const onChangeUser = (e, user) => {
    if (!user) return setClock({ ...clock, userId: null, user: null });

    return setClock({ ...clock, userId: user.userId, user });
  };

  const onSubmitClock = async () => {
    try {
      await postClocks(clock);
      successSnackbar();
      fetchClocks();
    } catch (err) {
      errorSnackbar(err);
    }
  };

  const onDeleteClock = async (_id) => {
    try {
      await deleteClock({ _id });
      successSnackbar();
      fetchClocks();
    } catch (err) {
      errorSnackbar(err);
    }
  };

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} container alignContent="flex-start" spacing={1}>
          <Grid item xs={4}>
            <KeyboardDateTimePicker
              label="Date Time"
              value={clock.date}
              onChange={(date) => setClock({ ...clock, date })}
              disableFuture
              autoOk
              fullWidth
              format="yyyy/MM/dd HH:mm:ss"
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              options={clockTypes}
              onChange={(e, type) => setClock({ ...clock, type })}
              value={clock && clock.type}
              autoHighlight
              autoSelect
              renderInput={(props) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <TextField {...props} label="Type" />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              options={users}
              getOptionLabel={(v) => `${v.userId} - ${v.userName}`}
              getOptionSelected={(option, value) =>
                option?.userId === value?.userId
              }
              onChange={onChangeUser}
              value={clock.user}
              autoHighlight
              autoSelect
              renderInput={(props) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <TextField {...props} label="User" />
              )}
            />
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={1}>
            <Button
              type="submit"
              onClick={onSubmitClock}
              color="primary"
              variant="contained"
            >
              Upload
            </Button>
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={12} />
          <Grid item xs={12} />
          <Grid item xs={12}>
            <Pagination
              count={30}
              page={page}
              onChange={onChange}
              color="primary"
              siblingCount={2}
              classes={{ ul: style.ul }}
            />
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={12} />
          <Grid item xs={3}>
            <Typography variant="h6">Date Time</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h6">Type</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h6">User</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h6">CreateBy</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h6">IP</Typography>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={12} />
          {clocks.map(({ _id, type, date, userId, remote, ip, author }) => {
            const typeString = remote ? `遠端${type}` : type;
            return (
              <Fragment key={_id}>
                <Grid item xs={3}>
                  <Typography>{`${format(
                    new Date(date),
                    'yyyy/MM/dd kk:mm:ss'
                  )}`}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography>{typeString}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography>{usersMap[userId]}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography>{usersMap[author]}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography>{ip}</Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    onClick={() => onDeleteClock(_id)}
                    aria-label="delete"
                    style={{ paddingTop: '6px' }}
                  >
                    <Delete />
                  </IconButton>
                </Grid>
              </Fragment>
            );
          })}
        </Grid>
      </Grid>
      <GoToTop />
    </Container>
  );
}

export default Attendance;
