/**
 * fetch api status
 */
export const status = (res) =>
  res.ok ? Promise.resolve(res) : Promise.reject(res);

/**
 * fetch api json
 */
export const json = (res) => res.json();

/**
 * fetch api error
 */
export const error = (err) => {
  // eslint-disable-next-line no-console
  console.error(err);
};

/**
 * fetch api error
 */
export const noLogErrorMessage = () => {};
