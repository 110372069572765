import { keyBy } from 'lodash';
import { createSelector } from 'reselect';

// However, when the selector is used in multiple component instances and depends on the component's props, you need to ensure that each component instance gets its own selector instance (see here for a more thorough explanation of why this is necessary):
// https://react-redux.js.org/api/hooks

export const getProjectsHash = createSelector(
  ({ projects }) => projects,
  (projects) => keyBy(projects, 'projectId')
);

export const getActiveProjects = createSelector(
  ({ projects }) => projects,
  (projects) => projects.filter((v) => v.projectActive)
);
