import {
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { endOfToday, format, startOfToday } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getClocks, postClocks } from '../../api/clocks';
import { fetchProjects } from '../../ducks/projects';
import { fetchSetting } from '../../ducks/setting';
import useSnackbar from '../../hooks/useSnackbar';
import EndNormalButton from './EndNormalButton';
import EndOverTimeButton from './EndOverTimeButton';
import StartNormalButton from './StartNormalButton';
import StartOverTimeButton from './StartOverTimeButton';

function Clock({ history }) {
  const userId = useSelector((state) => state.auth.userId);
  const allowPhoneClockIn = useSelector(
    (state) => state.setting.allowPhoneClockIn
  );
  const useOvertimeFeature = useSelector(
    ({ setting }) => setting.useOvertimeFeature
  );
  const [clocks, setClocks] = useState([]);
  const dispatch = useDispatch();
  const { errorSnackbar } = useSnackbar();
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('xs'));
  if (isPhone && !allowPhoneClockIn) history.push('/daily-plan');

  useEffect(() => {
    getClocks({
      startDate: startOfToday(),
      endDate: endOfToday(),
      userId,
    }).then((payload) => setClocks(payload));
    dispatch(fetchSetting());
    dispatch(fetchProjects());
  }, [userId, dispatch]);

  const onClick = (e) => {
    postClocks({ type: e.currentTarget.name, userId })
      .then((payload) => setClocks([...payload, ...clocks]))
      .catch(errorSnackbar);
  };

  const onClickRemoteButton = (e) => {
    postClocks({ type: e.currentTarget.name, userId, remote: true })
      .then((payload) => setClocks([...payload, ...clocks]))
      .catch(errorSnackbar);
  };

  const mdWidth = useOvertimeFeature ? 3 : 2;

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item md={mdWidth} xs={6}>
          <StartNormalButton
            userId={userId}
            clocks={clocks}
            setClocks={setClocks}
            remote={false}
          />
        </Grid>
        <Grid item md={mdWidth} xs={6}>
          <EndNormalButton
            userId={userId}
            clocks={clocks}
            setClocks={setClocks}
            remote={false}
          />
        </Grid>
        {useOvertimeFeature && (
          <>
            <Grid item md={mdWidth} xs={6}>
              <StartOverTimeButton
                userId={userId}
                clocks={clocks}
                setClocks={setClocks}
              />
            </Grid>
            <Grid item md={mdWidth} xs={6}>
              <EndOverTimeButton
                userId={userId}
                clocks={clocks}
                setClocks={setClocks}
              />
            </Grid>
          </>
        )}
        <Grid item md={mdWidth} xs={6}>
          <Button
            name="上班"
            onClick={onClickRemoteButton}
            variant="outlined"
            color="primary"
            fullWidth
          >
            遠端上班
          </Button>
        </Grid>
        <Grid item md={mdWidth} xs={6}>
          <EndNormalButton
            userId={userId}
            clocks={clocks}
            setClocks={setClocks}
            remote
          />
        </Grid>
        <Grid item md={mdWidth} xs={6}>
          <Button
            name="公出"
            onClick={onClick}
            variant="outlined"
            color="primary"
            fullWidth
          >
            公出
          </Button>
        </Grid>
        <Grid item md={mdWidth} xs={6}>
          <Button
            name="公出返回"
            onClick={onClick}
            variant="outlined"
            color="primary"
            fullWidth
          >
            公出返回
          </Button>
        </Grid>
        {clocks.map(({ type, date, remote = false } = {}) => {
          const typeString = remote ? `遠端${type}` : type;
          return (
            <Grid item xs={12} key={date}>
              <Typography>
                {`${format(
                  new Date(date),
                  'yyyy/MM/dd kk:mm:ss'
                )} - ${typeString}`}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}

Clock.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default Clock;
