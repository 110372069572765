import { Button, Container, Grid, TextField, Tooltip } from '@material-ui/core';
import useSnackbar from 'hooks/useSnackbar';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getCompanies } from '../../api/companies';
import { setItem } from '../../helpers/localStorage';
import style from './index.module.css';

/**
 * SignInCompany
 * - 自動轉大寫
 * - 可以按 enter 登入
 */
function SignInCompany({ history }) {
  const [company, setCompany] = useState('');
  const { errorSnackbar } = useSnackbar();

  const onChange = (e) => setCompany(e.target.value.toUpperCase().trim());

  /**
   * use form to more semantic but need to pass e.preventDefault();
   * instead of below line.
   * const onKeyPress = (e) => e.key === 'Enter' && signIn();
   */
  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const companies = await getCompanies();
      const isCompanyExist = companies.find((c) => c.company === company);
      if (!isCompanyExist) throw new Error('此公司不存在');

      setItem('company', company);

      history.push('/');
    } catch (error) {
      errorSnackbar(error.message);
    }
  };

  return (
    <Container>
      <form onSubmit={onSubmit}>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          alignContent="center"
          classes={{ root: style.Grid }}
        >
          <Grid item xs={12} container justifyContent="center">
            <TextField
              label="Company Name"
              id="company"
              autoFocus
              value={company}
              onChange={onChange}
              inputProps={{ className: style.TextField }}
            />
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <Tooltip title="Next (Enter)">
              <Button type="submit" variant="outlined">
                Next
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

SignInCompany.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default SignInCompany;
