/* eslint-disable react/jsx-props-no-spreading */
import { Button } from '@material-ui/core';
import { PhotoCamera } from '@material-ui/icons';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
/**
 * 消除醜醜的按鈕
 * https://kiranvj.com/blog/blog/file-upload-in-material-ui/
 * https://github.com/mui-org/material-ui/issues/9716
 * @param {*} props
 * @returns
 */
function UploadButton({ children, onChange, inputProps, ...rest }) {
  // https://stackoverflow.com/questions/29420835/how-to-generate-unique-ids-for-form-labels-in-react
  const [id] = useState(uniqueId('upload-button-'));

  return (
    <label htmlFor={id}>
      <input
        accept="image/*"
        type="file"
        id={id}
        style={{ display: 'none' }}
        onChange={onChange}
        {...inputProps}
      />
      <Button
        component="span"
        color="primary"
        variant="outlined"
        startIcon={<PhotoCamera />}
        {...rest}
      >
        {children}
      </Button>
    </label>
  );
}

UploadButton.propTypes = {
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

UploadButton.defaultProps = {
  children: undefined,
  inputProps: undefined,
};

export default UploadButton;
