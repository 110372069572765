import { isValid } from 'date-fns';

/**
 * get ISO String
 * @param {Date | string} [date]
 * @returns {String}
 */
export const getISODate = (date) =>
  isValid(new Date(date))
    ? new Date(date).toISOString()
    : new Date().toISOString();

export default {
  getISODate,
};
