import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';

import GoToTop from 'components/GoToTop';
import { getUsers, postUsers } from '../../api/users';
import { error } from '../../helpers/fetch';
import User from './User';

const initUser = () => ({
  userId: '',
  userName: '',
  role: '',
  password: '',
  email: '',
  salary: 0,
});

/**
 * onclick => post to database => redux fetch data => refresh projects page
 */
export function Users() {
  const [users, setUsers] = useState([]);

  const [user, setUser] = useState(initUser);

  const { enqueueSnackbar } = useSnackbar();

  const fetchUsers = useCallback(
    () =>
      getUsers()
        .then((payload) => setUsers(payload))
        .catch(error),
    []
  );

  /**
   * fetch projects
   * 由於 useEffect 回傳值不能為 Promise
   * 因此用大括號包住
   */
  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const onSubmit = (e) => {
    e.preventDefault();

    /**
     * 防止相同的 ID
     */
    if (users.some((v) => v.userId === user.userId)) {
      enqueueSnackbar('已經有了相同的 User ID 喔!', { variant: 'error' });
      return;
    }

    postUsers(user)
      .then(
        () => enqueueSnackbar('上傳成功', { variant: 'success' }),
        () => {
          enqueueSnackbar('上傳失敗', { variant: 'error' });
          return Promise.reject();
        }
      )
      .then(() => {
        setUser(initUser());
        fetchUsers();
      })
      .catch(error);
  };

  return (
    <Container>
      <Grid container spacing={1}>
        <Grid item sm={2} xs={12}>
          <TextField
            label="User ID"
            value={user.userId}
            onChange={(e) =>
              setUser({ ...user, userId: e.target.value.toUpperCase() })
            }
            required
            fullWidth
          />
        </Grid>
        <Grid item sm={2} xs={12}>
          <TextField
            label="User Name"
            value={user.userName}
            onChange={(e) =>
              setUser({ ...user, userName: e.target.value.toUpperCase() })
            }
            required
            fullWidth
          />
        </Grid>
        <Grid item sm={2} xs={12}>
          <FormControl fullWidth required>
            <InputLabel>權限</InputLabel>
            <Select
              label="權限"
              value={user.role}
              onChange={(e) => setUser({ ...user, role: e.target.value })}
              fullWidth
            >
              <MenuItem value="ADMIN">ADMIN</MenuItem>
              <MenuItem value="KEEPER">KEEPER</MenuItem>
              <MenuItem value="STAFF">STAFF</MenuItem>
              <MenuItem value="INTERN">INTERN</MenuItem>
              <MenuItem value="INACTIVE">INACTIVE</MenuItem>
              {/* <Tooltip title="適用於不用打卡的管理者">
                <MenuItem value="ADMIN">ADMIN</MenuItem>
              </Tooltip>
              <Tooltip title="適用於管理資料的使用者">
                <MenuItem value="KEEPER">KEEPER</MenuItem>
              </Tooltip>
              <Tooltip title="適用於一般員工">
                <MenuItem value="STAFF">STAFF</MenuItem>
              </Tooltip>
              <Tooltip title="適用於實習生">
                <MenuItem value="INTERN">INTERN</MenuItem>
              </Tooltip>
              <Tooltip title="適用於離職員工">
                <MenuItem value="INACTIVE">INACTIVE</MenuItem>
              </Tooltip> */}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={2} xs={12}>
          <TextField
            label="Password"
            value={user.password}
            onChange={(e) => setUser({ ...user, password: e.target.value })}
            fullWidth
          />
        </Grid>
        <Grid item sm={2} xs={12}>
          <TextField
            label="Email"
            value={user.email}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
            fullWidth
          />
        </Grid>
        <Grid item xs="auto">
          <Button
            type="submit"
            onClick={onSubmit}
            color="primary"
            variant="contained"
          >
            Upload
          </Button>
        </Grid>
        <Grid item xs={12}>
          <br />
        </Grid>
        {users.map((v) => (
          <User
            key={v.userId}
            userId={v.userId}
            userName={v.userName}
            role={v.role}
            email={v.email}
            salary={v.salary}
            fetchUsers={fetchUsers}
          />
        ))}
        <Grid item xs={12}>
          權限說明
          <ul>
            <li>
              ADMIN:
              適用於不用打卡的管理者，可以新增專案與使用者、調整設定、接收 Email
              訊息。
            </li>
            <li>KEEPER: 適用於管理資料的使用者，權限與 ADMIN 相同。</li>
            <li>STAFF: 適用於一般使用者。</li>
            <li>INTERN: 適用於實習生，現階段與 STAFF 無差別。</li>
            <li>INACTIVE: 適用於離職員工，使其無法再登入。</li>
          </ul>
        </Grid>
      </Grid>
      <GoToTop />
    </Container>
  );
}

export default Users;
