import { Button, Container, Grid, Typography } from '@material-ui/core';
import { PhotoCamera } from '@material-ui/icons';
import { endOfToday, format, startOfToday } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPrivateImage, postPrivateImage } from '../../api/privateImage';
import { getRemoteClocks, postRemoteClocks } from '../../api/remoteClocks';
import { SIGN_OUT } from '../../ducks/account';
import { fetchSetting } from '../../ducks/setting';
import useSnackbar from '../../hooks/useSnackbar';
import style from './index.module.css';

function getLocation() {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      reject(new Error('User denied Geolocation'));
    } else {
      navigator.geolocation.getCurrentPosition(resolve, reject, {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      });
    }
  });
}

function RemoteClock() {
  const { savingSnackbar, savedSnackbar, errorSnackbar } = useSnackbar();
  const [company, userId] = useSelector((state) => [
    state.auth.company,
    state.auth.userId,
  ]);
  const dispatch = useDispatch();
  const [remoteClocks, setRemoteClocks] = useState([]);
  const [location, setLocation] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const position = await getLocation();
        setLocation({
          type: 'Point',
          coordinates: [position.coords.latitude, position.coords.longitude],
        });
      } catch (error) {
        // eslint-disable-next-line no-alert
        alert(`${error?.message} 必須開啟定位功能`);
        dispatch({ type: SIGN_OUT });
      }

      const remoteClockDataArray = await getRemoteClocks({
        startDate: startOfToday(),
        endDate: endOfToday(),
        userId,
      });

      const remoteClockDataImageArray = await Promise.all(
        remoteClockDataArray.map(async (item) => {
          const url = item.filePath
            ? await getPrivateImage({ filename: item.filePath })
            : null;
          return {
            ...item,
            url,
          };
        })
      );

      setRemoteClocks(remoteClockDataImageArray);
    };

    fetchData();

    dispatch(fetchSetting());
  }, [userId, dispatch]);

  const onChange = async (e) => {
    try {
      const file = e.target.files?.[0];
      const filename = file ? `${company}/${window.crypto.randomUUID()}` : null;

      savingSnackbar();

      const [, [item]] = await Promise.all([
        file && postPrivateImage({ file, filename }),
        postRemoteClocks({
          type: e.currentTarget.name,
          userId,
          location,
          filePath: filename,
        }),
      ]);

      const url = file ? await getPrivateImage({ filename }) : null;

      setRemoteClocks([{ ...item, url }, ...remoteClocks]);

      savedSnackbar();
    } catch (error) {
      errorSnackbar(error);
    }
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <label htmlFor="早安">
            <input
              accept="image/*"
              type="file"
              id="早安"
              name="早安"
              style={{ display: 'none' }}
              onChange={onChange}
            />
            <Button
              component="span"
              color="primary"
              variant="outlined"
              startIcon={<PhotoCamera />}
              fullWidth
            >
              早安
            </Button>
          </label>
        </Grid>
        <Grid item xs={6}>
          <Button
            name="早安"
            onClick={onChange}
            variant="outlined"
            color="primary"
            fullWidth
          >
            早安
          </Button>
        </Grid>
        <Grid item xs={6}>
          <label htmlFor="午安">
            <input
              accept="image/*"
              type="file"
              id="午安"
              name="午安"
              style={{ display: 'none' }}
              onChange={onChange}
            />
            <Button
              component="span"
              color="primary"
              variant="outlined"
              startIcon={<PhotoCamera />}
              fullWidth
            >
              午安
            </Button>
          </label>
        </Grid>
        <Grid item xs={6}>
          <Button
            name="午安"
            onClick={onChange}
            variant="outlined"
            color="primary"
            fullWidth
          >
            午安
          </Button>
        </Grid>
        <Grid item xs={6}>
          <label htmlFor="晚安">
            <input
              accept="image/*"
              type="file"
              id="晚安"
              name="晚安"
              style={{ display: 'none' }}
              onChange={onChange}
            />
            <Button
              component="span"
              color="primary"
              variant="outlined"
              startIcon={<PhotoCamera />}
              fullWidth
            >
              晚安
            </Button>
          </label>
        </Grid>
        <Grid item xs={6}>
          <Button
            name="晚安"
            onClick={onChange}
            variant="outlined"
            color="primary"
            fullWidth
          >
            晚安
          </Button>
        </Grid>
        <Grid item xs={6}>
          <label htmlFor="加班">
            <input
              accept="image/*"
              type="file"
              id="加班"
              name="加班"
              style={{ display: 'none' }}
              onChange={onChange}
            />
            <Button
              component="span"
              color="primary"
              variant="outlined"
              startIcon={<PhotoCamera />}
              fullWidth
            >
              加班
            </Button>
          </label>
        </Grid>
        <Grid item xs={6}>
          <Button
            name="加班"
            onClick={onChange}
            variant="outlined"
            color="primary"
            fullWidth
          >
            加班
          </Button>
        </Grid>
        <Grid item xs={6}>
          <label htmlFor="加班結束">
            <input
              accept="image/*"
              type="file"
              id="加班結束"
              name="加班結束"
              style={{ display: 'none' }}
              onChange={onChange}
            />
            <Button
              component="span"
              color="primary"
              variant="outlined"
              startIcon={<PhotoCamera />}
              fullWidth
            >
              加班結束
            </Button>
          </label>
        </Grid>
        <Grid item xs={6}>
          <Button
            name="加班結束"
            onClick={onChange}
            variant="outlined"
            color="primary"
            fullWidth
          >
            加班結束
          </Button>
        </Grid>
        {remoteClocks.map(({ type, date, url } = {}) => {
          const typeString = type;
          return (
            <Grid item xs={12} key={date}>
              <Typography>
                {`${format(
                  new Date(date),
                  'yyyy/MM/dd kk:mm:ss'
                )} - ${typeString}`}
              </Typography>
              {/*
               * 替代圖片方法
               * react onError 設定 style.display = 'none'; 後
               * 上傳圖片後會 rerender，但僅會改變有變化的 dom 的部分，因此會有一直都有 style.display = 'none'; 的問題
               * https://stackoverflow.com/questions/3984287/how-to-show-alternate-image-if-source-image-is-not-found-onerror-working-in-ie
               * https://stackoverflow.com/questions/22051573/how-to-hide-image-broken-icon-using-only-css-html
               * https://stackoverflow.com/questions/22051573/how-to-hide-image-broken-icon-using-only-css-html
               * https://github.com/vercel/next.js/discussions/14772
               */}
              {url && <img src={url} alt="Daily Plan" className={style.img} />}
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}

export default RemoteClock;
