import { Container, Grid, Link, Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { endOfDay, format, startOfDay, sub } from 'date-fns';
import { memoize } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GoToTop from 'components/GoToTop';
import { getPrivateImage } from '../../api/privateImage';
import { getRemoteClocks } from '../../api/remoteClocks';
import { fetchUsers } from '../../ducks/users';
import { error } from '../../helpers/fetch';
import style from './index.module.css';

const reduce = memoize((users) =>
  users.reduce((dict, v) => ({ ...dict, [v.userId]: v.userName }), {})
);

function RemoteAttendance() {
  const [page, setPage] = useState(1);
  const [remoteClocks, setRemoteClocks] = useState([]);

  const users = useSelector((state) => state.users);
  const usersMap = reduce(users);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const date = sub(new Date(), { days: page - 1 });

        const startDate = startOfDay(date);
        const endDate = endOfDay(date);

        const remoteClockDataArray = await getRemoteClocks({
          startDate,
          endDate,
        });

        const remoteClockDataImageArray = await Promise.all(
          remoteClockDataArray.map(async (item) => {
            const url = await getPrivateImage({ filename: item.filePath });
            return {
              ...item,
              url,
            };
          })
        );

        setRemoteClocks(remoteClockDataImageArray);
      } catch (e) {
        error(e);
      }
    };

    fetchData();
  }, [page]);

  const onChange = (event, value) => {
    setPage(value);
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Pagination
            count={30}
            page={page}
            onChange={onChange}
            color="primary"
            siblingCount={2}
            classes={{ ul: style.ul }}
          />
        </Grid>
        <Grid item xs={12} container spacing={1}>
          <Grid item xs={2}>
            <Typography variant="h6">Date Time</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h6">Type</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h6">User</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h6">Location</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6">Image</Typography>
          </Grid>
          <Grid item xs={12} />
          {remoteClocks.map(({ _id, type, date, userId, location, url }) => {
            const typeString = type;
            return (
              <Fragment key={_id}>
                <Grid item xs={2}>
                  <Typography>{`${format(
                    new Date(date),
                    'yyyy/MM/dd kk:mm:ss'
                  )}`}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography>{typeString}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography>{usersMap[userId]}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Link
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`https://google.com.tw/maps/search/${location.coordinates[0]}+${location.coordinates[1]}`}
                  >
                    {location.coordinates[0]}, {location.coordinates[1]}
                  </Link>
                </Grid>
                <Grid item xs={4}>
                  {url && <img src={url} alt={type} />}
                </Grid>
              </Fragment>
            );
          })}
        </Grid>
      </Grid>
      <GoToTop />
    </Container>
  );
}

export default RemoteAttendance;
