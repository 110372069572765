import {
  colors,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Add, Check, CloudUploadOutlined, Remove } from '@material-ui/icons';
import { endOfDay, startOfDay } from 'date-fns';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { putTimesheets } from '../../api/timesheets';
import {
  checkNormalHour,
  checkOverTimeHour,
} from '../../helpers/bindClockAndTimesheet';
import {
  calNormalHourByClocks,
  calOverTimeHourByClocks,
} from '../../helpers/clock';
import { appendOverTimes } from '../../helpers/timesheet';
import style from './index.module.css';
import reducer, { ADD_ROW, DELETE_ROW, GET } from './reducer';
import ConnectedTimesheetRow from './TimesheetRow';

/**
 * - 接收 props date
 * - 載入組件 => get state
 * - Submit => post state
 * - 使用者輸入存在此 state => 傳給每一 row state 和 send
 */
export function Timesheet({
  date,
  userId,
  lunchBreak,
  timesheets,
  clocks,
  disabled = false,
}) {
  const { enqueueSnackbar } = useSnackbar();
  /**
   * dispatch 名稱與 redux 衝突，所以改成 send
   * useReducer 初始值是在整個生命週期初始
   */
  const [state, send] = useReducer(reducer, timesheets);

  const [check, setCheck] = useState(false);

  const [clockHours, setClockHours] = useState({
    normalHour: 0,
    overTime: 0,
  });
  /**
   * 若 timesheets 變化 send
   * 一開始都是 []，所以得加這個才有變化
   */
  useEffect(() => {
    send({ type: GET, payload: timesheets });
  }, [timesheets]);

  useEffect(() => {
    setClockHours({
      normalHour: calNormalHourByClocks(clocks, lunchBreak),
      overTime: calOverTimeHourByClocks(clocks),
    });
  }, [clocks, lunchBreak]);

  useEffect(() => {
    setCheck(
      checkNormalHour(clocks, state, lunchBreak) &&
        checkOverTimeHour(clocks, state)
    );
  }, [clocks, state, lunchBreak]);

  const onSubmit = async () => {
    /**
     * 由於 setDateHours 回傳新的物件，因此不能放在外面，以避免重複執行。
     */
    const startDate = startOfDay(date);
    const endDate = endOfDay(date);

    putTimesheets({
      startDate,
      endDate,
      timesheets: appendOverTimes(state).map((value) => ({
        date,
        userId,
        ...value,
      })),
    }).then(
      () => enqueueSnackbar('Upload Success', { variant: 'success' }),
      (err) => enqueueSnackbar(err, { variant: 'error' })
    );
  };

  const onClickAddRow = () => send({ type: ADD_ROW });

  const onClickDeleteRow = () => send({ type: DELETE_ROW });

  return (
    <>
      <Grid item xs={12} container alignItems="center">
        <Typography
          display="inline"
          variant="subtitle1"
          color={disabled ? 'textSecondary' : 'initial'}
          classes={{ subtitle1: style.subtitle1 }}
        >
          {date.toLocaleDateString('zh-TW', {
            weekday: 'narrow',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })}
        </Typography>
        {/*
            https://material-ui.com/components/tooltips/
            By default disabled elements like <button> do not trigger user interactions
            so a Tooltip will not activate on normal events like hover.
            To accommodate disabled elements, add a simple wrapper element, such as a span.
          */}
        {!disabled && (
          <>
            {state.length === 0 && (
              <>
                <Tooltip title="Add">
                  <span>
                    <IconButton
                      size="small"
                      edge="end"
                      color="inherit"
                      onClick={onClickAddRow}
                    >
                      <Add />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Remove">
                  <span>
                    <IconButton
                      size="small"
                      edge="start"
                      color="inherit"
                      onClick={onClickDeleteRow}
                    >
                      <Remove />
                    </IconButton>
                  </span>
                </Tooltip>
              </>
            )}
            <Tooltip title="Upload">
              <span>
                <IconButton
                  size="small"
                  type="submit"
                  color="inherit"
                  onClick={onSubmit}
                >
                  <CloudUploadOutlined />
                </IconButton>
              </span>
            </Tooltip>
          </>
        )}
        {check && (
          <Tooltip title="打卡 專案 Match">
            <Check
              fontSize="small"
              style={{ color: colors.blue[800] }}
              classes={{ fontSizeSmall: style.fontSizeSmall }}
            />
          </Tooltip>
        )}
        <Tooltip title="打卡 工時 加班： -1h 為打卡異常，無法計算">
          <Typography
            display="inline"
            color="textSecondary"
            variant="caption"
            style={{ marginLeft: '5px' }}
          >
            {`${clockHours.normalHour}h ${clockHours.overTime}h`}
          </Typography>
        </Tooltip>
      </Grid>
      <Grid item xs={12} container spacing={1} classes={{ root: style.root }}>
        {state.map((value, index) => (
          <ConnectedTimesheetRow
            // eslint-disable-next-line no-underscore-dangle
            key={value._id}
            index={index}
            rowData={state[index]}
            send={send}
            disabled={disabled}
          />
        ))}
      </Grid>
    </>
  );
}

Timesheet.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  userId: PropTypes.string.isRequired,
  lunchBreak: PropTypes.shape({
    start: PropTypes.shape({
      hour: PropTypes.number,
      minute: PropTypes.number,
    }),
    end: PropTypes.shape({
      hour: PropTypes.number,
      minute: PropTypes.number,
    }),
  }).isRequired,
  timesheets: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      projectId: PropTypes.string,
      task: PropTypes.string,
      remark: PropTypes.string,
      normalHour: PropTypes.number,
      overTime: PropTypes.number,
    })
  ).isRequired,
  clocks: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      type: PropTypes.string,
    })
  ).isRequired,
  disabled: PropTypes.bool,
};

Timesheet.defaultProps = {
  disabled: false,
};

const mapState = ({ auth, setting }, ownProps) => ({
  userId: ownProps.userId || auth.userId,
  lunchBreak: setting.lunchBreak,
});

export default connect(mapState)(Timesheet);
