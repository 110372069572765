import { AppBar, Tab, Tabs } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  AccessTime,
  Assessment,
  Assignment,
  EventNote,
  ExitToApp,
  FormatListBulleted,
  Image,
  ListAlt,
  People,
  Person,
  Settings,
} from '@material-ui/icons';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { SIGN_OUT } from '../../ducks/account';
import style from './index.module.css';

const Header = React.memo(() => {
  const [value, setValue] = useState(0);
  const user = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const highRoles = ['ADMIN', 'KEEPER'];
  const remoteClock = 'REMOTE_CLOCK';

  const onChange = (event, newValue) => setValue(newValue);

  const { userName, role, permissions } = user;

  return (
    <AppBar position="static" classes={style}>
      <Tabs
        value={value}
        onChange={onChange}
        centered={!highRoles.includes(role)}
        indicatorColor="primary"
        variant={!highRoles.includes(role) ? 'standard' : 'scrollable'}
      >
        <Tab label={userName} icon={<Person />} />
        {isDesktop && (
          <Tab
            label="Clock"
            component={Link}
            to="/clock"
            icon={<AccessTime />}
          />
        )}
        {permissions.includes(remoteClock) && (
          <Tab
            label="Remote Clock"
            component={Link}
            to="/remote-clock"
            icon={<AccessTime />}
          />
        )}
        <Tab
          label="Timesheet"
          component={Link}
          to="/timesheets"
          icon={<ListAlt />}
        />
        <Tab
          label="Planning"
          component={Link}
          to="/daily-plan"
          icon={<Image />}
        />
        {highRoles.includes(role) && (
          <Tab
            label="Projects"
            component={Link}
            to="/projects"
            icon={<FormatListBulleted />}
          />
        )}
        {highRoles.includes(role) && (
          <Tab label="Users" component={Link} to="/users" icon={<People />} />
        )}
        {highRoles.includes(role) && (
          <Tab
            label="Metrics"
            component={Link}
            to="/metrics"
            icon={<Assessment />}
          />
        )}
        {highRoles.includes(role) && (
          <Tab
            label="Attendance"
            component={Link}
            to="/attendance"
            icon={<EventNote />}
          />
        )}
        {highRoles.includes(role) && permissions.includes(remoteClock) && (
          <Tab
            label="Remote Attendance"
            component={Link}
            to="/remote-attendance"
            icon={<EventNote />}
          />
        )}
        {highRoles.includes(role) && (
          <Tab
            label="Reports"
            component={Link}
            to="/reports"
            icon={<Assignment />}
          />
        )}
        {highRoles.includes(role) && (
          <Tab
            label="Settings"
            component={Link}
            to="/settings"
            icon={<Settings />}
          />
        )}
        <Tab
          label="Sign Out"
          onClick={() => dispatch({ type: SIGN_OUT })}
          icon={<ExitToApp />}
        />
      </Tabs>
    </AppBar>
  );
});

export default Header;
