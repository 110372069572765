import { Container, Grid, Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { endOfDay, startOfDay, sub } from 'date-fns';
import { memoize } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GoToTop from 'components/GoToTop';
import { getClocks } from '../../api/clocks';
import { getTimesheets } from '../../api/timesheets';
import ConnectedTimesheet from '../../components/Timesheet';
import { fetchProjects } from '../../ducks/projects';
import { fetchUsers } from '../../ducks/users';
import { error } from '../../helpers/fetch';
import TaskImage from './TaskImage';
import style from './index.module.css';

const reduce = memoize((users) =>
  users.reduce((dict, v) => ({ ...dict, [v.userId]: v.userName }), {})
);

const filterByUserId = memoize(
  (data, userId) => data.filter((v) => v.userId === userId),
  (...args) => JSON.stringify(args)
);

function Metrics() {
  const [page, setPage] = useState(1);
  const [clocks, setClocks] = useState([]);
  const [timesheets, setTimesheets] = useState([]);

  const users = useSelector((state) => state.users);
  const usersMap = reduce(users);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchProjects());
  }, [dispatch]);

  useEffect(() => {
    const date = sub(new Date(), { days: page - 1 });

    const startDate = startOfDay(date);
    const endDate = endOfDay(date);

    getClocks({ startDate, endDate })
      .then((payload) => setClocks(payload))
      .catch(error);

    getTimesheets({ startDate, endDate })
      .then((payload) => setTimesheets(payload))
      .catch(error);
  }, [page]);

  const onChange = (event, value) => {
    setPage(value);
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Pagination
            count={30}
            page={page}
            onChange={onChange}
            color="primary"
            siblingCount={2}
            classes={{ ul: style.ul }}
          />
        </Grid>
        <Grid item xs={12} container spacing={1}>
          {users
            .filter(
              ({ role }) =>
                role === 'KEEPER' || role === 'STAFF' || role === 'INTERN'
            )
            .map(({ userId }) => (
              <Fragment key={userId}>
                <Grid item>
                  <Typography variant="h6">{usersMap[userId]}</Typography>
                </Grid>
                <Grid item container spacing={1}>
                  <Grid
                    item
                    lg={9}
                    xs={12}
                    container
                    spacing={1}
                    alignContent="flex-start"
                  >
                    <ConnectedTimesheet
                      date={sub(new Date(), { days: page - 1 })}
                      timesheets={filterByUserId(timesheets, userId)}
                      clocks={filterByUserId(clocks, userId)}
                      userId={userId}
                    />
                  </Grid>
                  <Grid item lg={3} xs={12} container spacing={1}>
                    <TaskImage
                      date={sub(new Date(), { days: page - 1 })}
                      userId={userId}
                    />
                  </Grid>
                </Grid>
              </Fragment>
            ))}
        </Grid>
      </Grid>
      <GoToTop />
    </Container>
  );
}

export default Metrics;
