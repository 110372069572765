import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { zipObject } from 'lodash';
import React, { useEffect, useState } from 'react';
import { getPrivateImage, postPrivateImage } from '../../api/privateImage';
import { getPublicImage, postPublicImage } from '../../api/publicImage';
import UploadButton from '../../components/UploadButton';
import { error } from '../../helpers/fetch';
import { getItem } from '../../helpers/localStorage';
import useSnackbar from '../../hooks/useSnackbar';
import defaultMood0 from './defaultMood0.svg';
import defaultMood1 from './defaultMood1.svg';
import defaultMoodNegative1 from './defaultMoodNegative1.svg';
import style from './index.module.css';

const privateImgFilenames = ['mood/-1', 'mood/0', 'mood/1'];

const bulletinImgFilenames = [
  'bulletin/0',
  'bulletin/1',
  'bulletin/2',
  'bulletin/3',
  'bulletin/4',
  'bulletin/5',
  'bulletin/6',
  'bulletin/7',
  'bulletin/8',
  'bulletin/9',
  'bulletin/10',
  'bulletin/11',
  'bulletin/12',
  'bulletin/13',
  'bulletin/14',
  'bulletin/15',
  'bulletin/16',
  'bulletin/17',
  'bulletin/18',
  'bulletin/19',
  'bulletin/20',
  'bulletin/21',
  'bulletin/22',
  'bulletin/23',
];

const publicImgFilenames = ['logo', ...bulletinImgFilenames];

function Settings() {
  const [privateImgs, setPrivateImgs] = useState({});
  const [publicImgs, setPublicImgs] = useState({});
  const [company] = useState(getItem('company'));
  // const [logoImg, setLogoImg] = useState('');
  // const [bulletinImg, setBulletinImg] = useState('');

  const { savingSnackbar, savedSnackbar, errorSnackbar } = useSnackbar();

  useEffect(() => {
    const getImages = async () => {
      const privateImages = privateImgFilenames.map((filename) =>
        getPrivateImage({ filename })
          .then((url) => url)
          .catch(error)
      );
      const publicImages = publicImgFilenames.map((filename) =>
        getPublicImage({ company, filename })
          .then((url) => url)
          .catch(() => {})
      );

      setPrivateImgs(
        zipObject(privateImgFilenames, await Promise.all(privateImages))
      );
      setPublicImgs(
        zipObject(publicImgFilenames, await Promise.all(publicImages))
      );
    };

    getImages();
  }, [company]);

  const onChangePrivateImage = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Passing params using data-attributes
    // https://reactjs.org/docs/faq-functions.html
    const { filename } = e.target.dataset;

    savingSnackbar();

    postPrivateImage({ file, filename })
      .then(() => getPrivateImage({ filename }))
      .then((url) => setPrivateImgs((o) => ({ ...o, [filename]: url })))
      .then(savedSnackbar)
      .catch(errorSnackbar);
  };

  const onChangePublicImage = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Passing params using data-attributes
    // https://reactjs.org/docs/faq-functions.html
    const { filename } = e.target.dataset;

    savingSnackbar();

    postPublicImage({ file, filename })
      .then(() => getPublicImage({ company, filename }))
      .then((url) => setPublicImgs((o) => ({ ...o, [filename]: url })))
      .then(savedSnackbar)
      .catch(errorSnackbar);
  };

  return (
    <Container>
      {/* <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>登入頁面圖片設定</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={0}>
            <Grid item>
              <img
                src={bulletinImg}
                className={style.bulletinImg}
                alt="bulletin"
                onError={() => {
                  const hour = getHours(new Date());
                  if (hour > 6 && hour < 16) {
                    setBulletinImg(defaultBulletinAM);
                  } else {
                    setBulletinImg(defaultBulletinPM);
                  }
                }}
              />
            </Grid>
            <Grid
              item
              container
              spacing={5}
              justifyContent="center"
              alignContent="center"
              classes={{ root: style.form }}
            >
              <Grid item xs={12}>
                <ImageListItem>
                  <img
                    src={logoImg}
                    alt="logo"
                    className={style.logoImg}
                    onError={() => setLogoImg(defaultLogo)}
                  />
                  <ImageListItemBar
                    title="123"
                    subtitle={<span>by: </span>}
                    actionIcon={
                      <IconButton>
                        <Info />
                      </IconButton>
                    }
                  />
                </ImageListItem>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="User Name"
                  id="userName"
                  fullWidth
                  inputProps={{ className: style.TextField }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Password"
                  id="password"
                  type="password"
                  fullWidth
                  inputProps={{ className: style.TextField }}
                />
              </Grid>
              <Grid item xs={12} container justifyContent="center">
                <Tooltip title="登入 (Enter)">
                  <Button type="submit" variant="outlined">
                    Sign In
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <MaterialLink to="/signin-company" underline="always">
                  Sign in with another company
                </MaterialLink>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>心情指數 Icon 設定</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion> */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>登入頁面 Image 設定</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid container spacing={1} item>
              <Grid item xs={12}>
                <UploadButton
                  inputProps={{ 'data-filename': 'logo' }}
                  fullWidth
                  onChange={onChangePublicImage}
                >
                  Upload Logo
                </UploadButton>
              </Grid>
              <Grid item xs={12}>
                {publicImgs.logo && (
                  <img src={publicImgs.logo} alt="logo" className={style.img} />
                )}
              </Grid>
            </Grid>
            <Grid container spacing={1} item xs={12}>
              {bulletinImgFilenames.map((filename) => (
                <Grid key={filename} container spacing={0} item xs={4}>
                  <Grid item xs={12}>
                    <UploadButton
                      inputProps={{ 'data-filename': filename }}
                      fullWidth
                      onChange={onChangePublicImage}
                    >
                      {`${filename} 時`}
                    </UploadButton>
                  </Grid>
                  <Grid item xs={12}>
                    {publicImgs[filename] && (
                      <img
                        src={publicImgs[filename]}
                        alt={`${filename}`}
                        className={style.img}
                      />
                    )}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>心情指數 Icon 設定</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1} item xs={12}>
            <Grid item xs={4}>
              <UploadButton
                inputProps={{ 'data-filename': 'mood/-1' }}
                fullWidth
                onChange={onChangePrivateImage}
              >
                上傳心情指數不滿意的 icon
              </UploadButton>
            </Grid>
            <Grid item xs={4}>
              <UploadButton
                inputProps={{ 'data-filename': 'mood/0' }}
                fullWidth
                onChange={onChangePrivateImage}
              >
                上傳心情指數尚可的 icon
              </UploadButton>
            </Grid>
            <Grid item xs={4}>
              <UploadButton
                inputProps={{ 'data-filename': 'mood/1' }}
                fullWidth
                onChange={onChangePrivateImage}
              >
                上傳心情指數滿意的 icon
              </UploadButton>
            </Grid>

            <Grid item xs={4}>
              <img
                src={privateImgs['mood/-1']}
                alt="mood/-1"
                className={style.img}
                onError={() => {
                  setPrivateImgs((o) => ({
                    ...o,
                    'mood/-1': defaultMoodNegative1,
                  }));
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <img
                src={privateImgs['mood/0']}
                alt="mood/0"
                className={style.img}
                onError={() => {
                  setPrivateImgs((o) => ({ ...o, 'mood/0': defaultMood0 }));
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <img
                src={privateImgs['mood/1']}
                alt="mood/1"
                className={style.img}
                onError={() => {
                  setPrivateImgs((o) => ({ ...o, 'mood/1': defaultMood1 }));
                }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <br />
      <Typography variant="caption">
        註：目前無法重設回預設圖片，可重設回預設圖片的功能將於下一版本推出。
      </Typography>
    </Container>
  );
}

export default Settings;
